@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&family=Roboto+Condensed:wght@300;400;500&display=swap');


*{
    font-family: 'Inter', sans-serif;
font-family: 'Roboto Condensed', sans-serif;

}

.modal-backdrop.show {
    opacity: 0.5 !important;
}
.owl-theme .owl-nav{
    margin: 0px 0px 5px 0px !important;
}
/* 24-1-2024 */

.cat3:hover .cat,
.cat3:hover .cat2 {
  display: block !important;
}
/* 24-1-2024 */


.extramargin21{
    margin-left: 7rem;
    margin-right: 7rem;
}
a {
    text-decoration: none;
}
.qtycls::placeholder{
    color: #808080 !important;
}

.css-13cymwt-control{
    border-radius: 0px !important;
}


.hovereffect:hover{
    color: #059fe2;
}
.help{
    font-size: 13px;
    font-weight: 700;
}
.loadercls{
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}




.section_padding {
    padding: 4rem;
}

#root > div > div > div > span{
    z-index: 4;
}
.topfooter {
    display: flex;
    justify-content: space-between;
    background-color: #f7f7fa;
    border-top: 1px solid #f4f3f3;
    padding-top: 22px;
    padding-bottom: 22px;
    flex-wrap: wrap;
    padding-left: 20px;
    padding-right: 20px;
}

.sb_topfooter h4 {
    font-size: 19px;
    font-weight: 650;
    padding-bottom: 7px;
}

.sb_topfooter h5 {
    font-size: 14px;
    color: #9ba0b1;
    font-weight: 700;
    padding-bottom: 10px;
}

.sb_topfooter p {
    font-size: 14px;
    color: grey;
    font-weight: 400;
}

.sb_topfooter {
    width: 50%;
}
/* .shop{
    padding-top: 200px;
} */
.css-t3ipsp-control:hover {
    border-color: none !important;
}


.footer {
    background-color: white;
}

.sb_footer {
    display: flex;
    flex-direction: column;
}

.sb_footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    margin-bottom: 0.7rem;
}
.text23{
    font-size: 15px !important;
}
.sb_footer-links_div {
    /* width: 150px; */
    /* margin: 1rem; */
    margin: 0px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color: black;
    font-weight: bold;
}
/* .pagesizecls{
    width: 35px;
    display: flex;
    justify-content: center;
}
.pagesizecls > a{
    margin: 0px;
    width: 32px !important;
    display: flex;
    justify-content: center;
} */
a {
    color: gray;
    text-decoration: none;
}

.socialmedia {
    display: flex;
    flex-direction: row;
}

.socialmedia img {
    width: 80%;

}

.sb_footer-links_div h4 {
    font-size: 15px;
    line-height: 17px;
    margin-bottom: 0.9rem;
}

.sb_footer-links_div p {
    font-size: 14px;
    margin: 0.4rem 0;
    cursor: pointer;
    line-height: 1.1em;
    color: #4e5361;
    font-weight: 400;
}

.sb_footer-below {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.2rem;
}
.custombtn2{
    padding: 7px 12px !important; 
    font-size: 12px;
    font-weight: 600;
}

.sb_footer-below-links {
    display: flex;
    padding-left: 200px;
    justify-content: space-between;
    flex-direction: row;
    padding-bottom: 50px;
    gap: 20px;
}

.sb_footer-below-links p {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    margin-left: 2rem;
    color: black;
    font-weight: 600;
}

hr {
    color: white !important;
    width: 100%;
}

.sb_footer-copyright p {
    padding-left: 100px;
    font-size: 13px;
    line-height: 15px;
    color: black;
    text-align: center;
    font-weight: bold;
}

.bottom-footer {
    padding: 13px 0;
    background-color: #f7f7fa;
    border-top: 1px solid #e0e0e0;
}

.main-footer .media-icon i.fa {
    font-size: 28px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin-left: auto;
}


.bottom-footer .other-option a {
    padding-right: 13px;
    color: grey;
    font-size: 15px;
    font-weight: 500;
}


.responsivetitle2{
    display: flex;
    justify-content: end;
}

.video-title{
    padding-top: 15px;
    font-size: 15px;
    background-color: #f6f6f6;
}




@media screen and (max-width: 850px) {
    .sb_footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 768px) {
    .sb_footer-links_div p {
        margin-left: 50px;
    }

    .sb_footer-links_div h4 {
        margin-left: 50px;
    }
}

@media screen and (max-width: 550px) {
    .sb_footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .sb_footer-links_div {
        margin: 1rem 0px;
    }

    .sb_footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }

    .sb_footer-below {
        flex-direction: column;
        justify-content: left;
    }

    .sb_footer-below-links {
        flex-direction: column;
    }

    .sb_footer-below-links p {
        margin-left: 0rem;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 400px) {
    .sb_footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
}




/* header css */






/* brand page css */
.boxhover :hover {
    /* transition: all .2s ease-in-out; */
    cursor: pointer;
    /* transform: scale(1.1); */
    /* z-index: 1;   */
    /* box-shadow: 0px 0px 1px #ddd !important; */
    border: 1px solid #ddd !important;
}


/* Best-sellers page css */
.box1 {
    padding: 20px;
    background: #fff;
    border: 1px solid #ececec;
    margin-bottom: 5px;
}

.box2 {
    padding: 20px;
    background: #fff;
    border: 1px solid #ececec;
    margin-bottom: 5px;
}

.box3 {
    padding: 20px;
    background: #fff;
    border: 1px solid #ececec;
    margin-bottom: 5px;
}

.box4 {
    padding: 20px;
    background: #fff;
    border: 1px solid #ececec;
    margin-bottom: 5px;
}

.collapse-block-title {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    line-height: 1.2;
    padding-bottom: 15px;
    cursor: pointer;
    position: relative;
}

.topinput {
    color: #999;
    font-size: 16px;

}

.boxsmalltext {
    font-size: 16px;
    color: #5e5e5e;
    font-weight: 300;
    padding: 7px 0;
    display: inline-block;
    position: relative;

}

.boxsmalltext a {
    text-decoration: none;
    transition: all .2s ease-in-out;

}

.searchbox {
    background: #fff;
    height: 50px;
    border: 1px solid #ececec;
    position: relative;
    padding: 0 20px;
    margin-bottom: 10px;
}

.searchbox h4 {
    text-align: center;
    padding-top: 15px;
    color: #999;
    font-size: 16px;
   
   
}

.searchicon {
    position: absolute;
    bottom: 10px;
}






.redbutton {   
    display: flex;
    justify-content: center;
    gap: 98px;
}


.style-no h6 {
    padding-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 17px;
    padding-bottom: 5px;
    line-height: 1.3;
    color: #333;
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

}
.fa-regular{
    color: #059fe2;
}


.Price-box p {
    display: flex;
    align-items: center;
    justify-content: center;
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    color: #059fe2;
}

.Order-price span {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #333;
}

.products {
    background: none;
    height: 45px;
    /* border: 1px solid #ececec; */
    /* border-bottom: 1px solid rgba(128, 128, 128, 0.441); */
}

.products h4 {
    font-size: 30px;
    color: #060407;
    font-weight: 300;
    display: inline-block;
    padding-right: 5px;
}

.products span {
    font-size: 12px;
    color: #565656;
    /* display: inline-block; */
    line-height: 19px;
}

ul > li.active a{
    border: none !important;
 }
 ul > li a{
        border-radius: 0px !important;
 }
 .disabledcls{
    display: none;
 }
 .extrapadding23{
    display: block;
    padding: 0px 355px !important;
    color: red !important;
    background-color: blueviolet !important;
 }

 /* Buttion category page */
.show-more-button {
    text-transform: uppercase;
    background: #059fe2;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    padding: 12px 16px;
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    border: none; 
    cursor: pointer; 
    transition: background 0.3s ease; 
  }

  .boxsmalltext:hover {
    transform: translateX(12px);
    font-weight: 400;
    transition: transform 0.3s ease-in-out;
  }
  
  .boxsmalltext a:hover {
    transform: translateX(12px);
    font-weight: 400;
    transition: transform 0.3s ease-in-out;
  }
  
  .show-more-button:hover {
    background: #000; 
  }
.lastbutton {

    /* padding: 11px 11px 11px 29px; */
    padding-bottom: 8px;
    border-color: #059fe2;
    /* position: relative; */
    /* 30-1-2024 start  */
    /* margin-top: 30px; */
    margin-top: 12px;
    /* 30-1-2024 end  */

}

.one {
    font-size: 13px;
    line-height: 20px;
   border-radius: 0px;
    background: #fff;
    color: black;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 2px solid #059fe2;
    margin-right: 5px;
    display: flex;
    margin: 0px;
    display: flex !important;
    gap: 2px;

}

.two:hover {
    background: black;
    color: #fff;
    border: 0px;
}

.one:hover {
    background: #059fe2;
    color: #fff;
    border: 0px;
}

.two {
    font-size: 13px;
    border-radius: 0px;
    line-height: 20px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 2px solid #059fe2;
    /* display: inline-block; */



}

.sidebar {
    border-radius: 0px;
    width: 50%;
    border: 1px solid #e3e3e3;
    /* background: #fff; */
    font-size: 14px;
    color: #6d6d6d;
    line-height: 18px;
    padding: 5px 20px 5px 10px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    margin: 0 5px;
}

.rightone {
    box-sizing: border-box;
    align-items: center;
    background: #fff;
    text-align: center;
    border: 2px solid #e6e6e6;
    /* 30-1-2024 start  */
    padding: 0 10px 10px 10px;
    padding-top: 0px;
    /* 30-1-2024 end  */
    margin-bottom: 15px;
    position: relative;
}

.rightone:hover {
    border: 2px solid #059fe2;
}

.imageheart {
    font-size: 30px;
    padding-right: 20px;
    color: rgba(128, 128, 128, 0.548);
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    justify-content: right;
    flex-direction: column;
    opacity: 0;
    transition: 0.6s;

}


.imageheart:hover {
    opacity: 1;
}

.imageheart {
    transform: translateY(25px);
    transition: transform 0.6s;
}

.rightone:hover>.imageheart {
    opacity: 1;
}

.eyeone {
    color: #059fe2;
    position: absolute;
    display: flex;
    /* padding-left: 2px; */
    /* left: 15px; */
    bottom: 35px;
    /* content: "\f06e"; */
    font-family: 'FontAwesome';
}

.one .lastbutton span{
    color: #059fe2 !important;
    }
    
    
    .one:hover .lastbutton i {
        color: #fff;
    }



.firstbigimageowl .item1{
    width: 100vw;
}
.firstbigimageowl .item1 img{
    width: 100vw;
    object-fit: cover;
}


.lastbutton {
    display: flex !important;

    justify-content: space-around;
    gap: 2px;
}

.minus {
    font-size: 10px;
    opacity: 0;
    transition: 0.6s;
}

.minus:hover {
    opacity: 1;
}

.minus {
    transform: left translateY(25px);
    transition: transform 0.6s;

}

.minus {
    transform: translateY(10px);


}

.boxsmalltext a:hover>.minus {
    opacity: 1;
}

.boxsmalltext a:hover {
    color: #059fe2;
}

.sortby {

    display: flex;
    font-Size: 14px;
    font-Weight: 300;
    color: #333;
    padding-Top: 15px;
}


@media screen and (max-width: 600px) {

    .sidebar {

        width: 70%;
    }

}

@media screen and (max-width: 500px) {

    .sidebar {
        width: auto;
    }

    .sortby {
        width: auto;
        font-Size: 11px;

    }

}

.bulkorder-text {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.longbutton {

    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #fff;
    border: 1px dashed #b2b2b2;
    color: #858585;
    font-weight: 400;
    font-size: 16px;

}



/* Home page css */


.Topbackground {
    height: 420px;
}


/* added rfl */
.rfq-id,
.order-id,
.separator,
.item-count,
.placed-on {
  color: black;
  font-weight: 500;
}
.rfq-id {
    font-weight: 400; 
  }

.separator {
  margin: 0 10px;
}

@media screen and (max-width: 600px) {
  .gap-adjust {
    font-size: 14px; 
  }
  .separator {
    margin: 0 5px; /* Reduced margin for mobile view */
  }
}
  


.firstbigimageowl .owl-theme .owl-dots .owl-dot {
    display: inline-block;
    width: 14px;
    display: inline;
    margin: 0px 3px;
    height: 14px;
    border-radius: 50%;
    border: 1px solid #585858;
}

.firstbigimageowl .owl-nav{
    height: 0px;
    padding: 0px;
    margin: 0px;
}

 .owl-theme .owl-dots .owl-dot .active{
    background-color: #8d8d8d !important;
}

#root > div > div > section:nth-child(2) > div > div > div > div > div.owl-dots > button.owl-dot.active{
    background-color: #8d8d8d !important;

}

.firstbigimageowl .owl-dots{
    position: relative;
    top: -82px;
}



.white-bg {
    background: #fff;
    padding: 10px 20px 30px 20px;
    border-radius: 7px;
    width: 100%;


}


.item {
    border: 1px solid #e3e3e3;
    /* height: 280px; */
    /* height: 302px; */
    align-items: center;
    height: 250px;
}

.itematv {
    border: 1px solid #e3e3e3;
    /* height: 280px; */
    /* height: 302px; */
    align-items: center;
    height: 228px;
}

/* .extrades234{
    padding-bottom: 0px !important;
} */


.newsize3458{
    display: flex !important;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.owlimg1 {
    /* padding-top: 30px; */
    padding-top: 0px;
    left: 20px;
    right: 0;
    margin: auto;
    max-width: 100%;

}


.textimage {
    font-size: 15px;
    display: flex;
    padding: 4px 3px 0px 3px;
    line-height: 1.2;
    letter-spacing: 0.6px;
    justify-content: center;
    font-weight: 700;
    color: #333 !important;
}

.text2image {
    font-size: 16px;
    display: flex;
    justify-content: center;
    font-weight: 600;
}

.text3image {
    font-size: 16px;
    display: flex;
    justify-content: center;
    font-weight: 600;
}
/* Top .owl-carousel */
#root>div>div>section:nth-child(2)>div>div>div>div>div.owl-nav>button.owl-prev span {
    position: absolute;
    top: 35%;
    /* 30-1-2024 start  */
    left: 44px;
    /* 30-1-2024 end  */
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(2)>div>div>div>div>div.owl-nav>button.owl-next span {
    position: absolute;
    top: 35%;
    right: 44px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(2)>div>div>div>div>div.owl-nav>button.owl-prev span:hover {
    background: #059fe2;
}

#root>div>div>section:nth-child(2)>div>div>div>div>div.owl-nav>button.owl-next span:hover {
    background: #059fe2;
}


/* Best Selling Products */

#root>div>div>section:nth-child(4)>div>div>div.owl-nav>button.owl-prev span {
    position: absolute;
    top: 35%;
    left: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(4)>div>div>div.owl-nav>button.owl-next span {
    position: absolute;
    top: 35%;
    right: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(4)>div>div>div.owl-nav>button.owl-prev span:hover {
    background: #059fe2;
}

#root>div>div>section:nth-child(4)>div>div>div.owl-nav>button.owl-next span:hover {
    background: #059fe2;
}

/* .......Shoes Safety....... */

#root>div>div>section:nth-child(5)>div>div>div.owl-nav>button.owl-prev span {
    position: absolute;
    top: 35%;
    left: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(5)>div>div>div.owl-nav>button.owl-next span {
    position: absolute;
    top: 35%;
    right: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(5)>div>div>div.owl-nav>button.owl-prev span:hover {
    background: #059fe2;
}

#root>div>div>section:nth-child(5)>div>div>div.owl-nav>button.owl-next span:hover {
    background: #059fe2;
}

/* .........Eye Protection......... */

#root>div>div>section:nth-child(6)>div>div>div.owl-nav>button.owl-prev span {
    position: absolute;
    top: 35%;
    left: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(6)>div>div>div.owl-nav>button.owl-next span {
    position: absolute;
    top: 35%;
    right: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(6)>div>div>div.owl-nav>button.owl-prev span:hover {
    background: #059fe2;
}

#root>div>div>section:nth-child(6)>div>div>div.owl-nav>button.owl-next span:hover {
    background: #059fe2;
}

/* ..........Hand Protection.......... */

#root>div>div>section:nth-child(7)>div>div>div.owl-nav>button.owl-prev span {
    position: absolute;
    top: 35%;
    left: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(7)>div>div>div.owl-nav>button.owl-next span {
    position: absolute;
    top: 35%;
    right: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(7)>div>div>div.owl-nav>button.owl-prev span:hover {
    background: #059fe2;
}

#root>div>div>section:nth-child(7)>div>div>div.owl-nav>button.owl-next span:hover {
    background: #059fe2;
}


/* ........Hand Protection......... */


#root>div>div>section:nth-child(8)>div>div>div.owl-nav>button.owl-prev span {
    position: absolute;
    top: 35%;
    left: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(8)>div>div>div.owl-nav>button.owl-next span {
    position: absolute;
    top: 35%;
    right: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(8)>div>div>div.owl-nav>button.owl-prev span:hover {
    background: #059fe2;
}

#root>div>div>section:nth-child(8)>div>div>div.owl-nav>button.owl-next span:hover {
    background: #059fe2;
}

/* Our Top Brands */
#root>div>div>section:nth-child(9)>div>div>div.owl-nav>button.owl-prev span {
    position: absolute;
    top: 35%;
    left: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}


/* .....BRAND PAGE CSS...... */
.brand-section {
    font-size: 26px;
    line-height: 1.2;
    padding: 15px 0px 15px 15px;
    border-bottom: 1px solid #efefef;
    background: #fff;
    border-radius: 7px 7px 0 0;
    color: #059fe2;



}

#root>div>div>section:nth-child(9)>div>div>div.owl-nav>button.owl-prev span:hover {
    background: #059fe2;
}

#root>div>div>section:nth-child(9)>div>div>div.owl-nav>button.owl-next span {
    position: absolute;
    top: 35%;
    right: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(9)>div>div>div.owl-nav>button.owl-next span:hover {
    background: #059fe2;
}


/* Our Top Brand Owl */
#root>div>div>section:nth-child(10)>div>div>div.owl-nav>button.owl-prev span {
    position: absolute;
    top: 0%;
    left: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(10)>div>div>div.owl-nav>button.owl-prev span:hover {
    background: #059fe2;
}

#root>div>div>section:nth-child(10)>div>div>div.owl-nav>button.owl-next span {
    position: absolute;
    top: 0%;
    right: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(10)>div>div>div.owl-nav>button.owl-next span:hover {
    background: #059fe2;
}


/* Hearing Protection */

#root>div>div>section:nth-child(11)>div>div>div.owl-nav>button.owl-prev span {
    position: absolute;
    top: 35%;
    left: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(11)>div>div>div.owl-nav>button.owl-prev span:hover {
    background: #059fe2;
}

#root>div>div>section:nth-child(11)>div>div>div.owl-nav>button.owl-next span {
    position: absolute;
    top: 35%;
    right: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(11)>div>div>div.owl-nav>button.owl-next span:hover {
    background: #059fe2;
}

/* ......Safety Clothing....... */
#root>div>div>section:nth-child(13)>div>div>div.owl-nav>button.owl-prev span {
    position: absolute;
    top: 35%;
    left: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(13)>div>div>div.owl-nav>button.owl-prev span:hover {
    background: #059fe2;
}

#root>div>div>section:nth-child(13)>div>div>div.owl-nav>button.owl-next span {
    position: absolute;
    top: 35%;
    right: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(13)>div>div>div.owl-nav>button.owl-next span:hover {
    background: #059fe2;
}





#root>div>div>section:nth-child(12)>div>div>div.owl-nav>button.owl-prev span {
    position: absolute;
    top: 35%;
    left: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(12)>div>div>div.owl-nav>button.owl-prev span:hover {
    background: #059fe2;
}

#root>div>div>section:nth-child(12)>div>div>div.owl-nav>button.owl-next span {
    position: absolute;
    top: 35%;
    right: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(12)>div>div>div.owl-nav>button.owl-next span:hover {
    background: #059fe2;
}

.heartimg {
    font-size: 28px;
    padding-right: 10px;
    color: rgba(128, 128, 128, 0.548);
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    justify-content: right;
    flex-direction: column;
    opacity: 0;
    transition: 0.6s;
}



.heartimg:hover {
    opacity: 1;
}

.heartimg {
    transform: translateY(25px);
    transition: transform 0.6s;
}

.item:hover>.heartimg {
    opacity: 1;
}

.trustpay h1 {
    text-align: center;
}

.trustpay p {
    text-align: center;
}

.great {
    align-items: center;
}

.twoimagecol {
    margin-bottom: 30px;
}

.BestSelling {
    color: #000;
    font-size: 23px;
    text-align: left;
    font-weight: 580;
    line-height: 1.2;
    padding-bottom: 25px;
    text-transform: capitalize;
}

.allbutton {
    position: absolute;
    right: 35px;
    top: px;
    background: #059fe2;
    color: #fff;
    /* padding: 8px 13px; */
    line-height: 1.2em;
    font-size: 13px;
    font-weight: 600;
}

.allbutton span a:hover {
    color: #000;
}
.custombtn2:hover{
    background-color: #000;
}

#root>div>div>section:nth-child(5)>div>h1>span>a>button:hover {
    background: black;
}

#root>div>div>section:nth-child(6)>div>h1>span>a>button:hover {
    background: black;
}

#root>div>div>section:nth-child(7)>div>h1>span>a>button:hover {
    background: #000;
}

#root>div>div>section:nth-child(8)>div>h1>span>a>button:hover {
    background: #000;
}




.owl-carousel .owl-item img {
    max-width: 100% !important;
}


.categoryList h4 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
    background: #059fe2;
    padding: 17px 22px;
}

.categoryList a {
    display: flex;
    align-items: center;
    width: 100%;
       /* 30-1-2024 start  */
    /* padding: 9px 22px; */
    padding: 12px 22px;
    /* 30-1-2024 end  */
    font-size: 15px;
    font-weight: 600;
    color: #000;
    border-bottom: 1px solid #ececec;
    direction: ltr;
}

.category {
    font-size: 16px;
    color: #3d3d3d;
    background: #efefef;
    display: inline-block;
    width: 100%;
    padding: 18px 22px;
    text-transform: capitalize;
}
/* 24-1-2024 */
.cat {
    display: none;
    position: absolute;
    right: -275px;
    top: 0px;
    background: white;
    width: 100%;
    border: 1px solid gray;
    height: 100%;
    overflow: visible;

}
/* 24-1-2024 */

.cat ul li a {
    color: #000;
    font-weight: 700;
    font-size: 14px;
    padding: 12px 20px 3px;
    box-sizing: border-box;
    border: none;
}

.cat ul li p {
    font-size: 14px;
    color: #545454;
    font-weight: 400;
    padding-left: 20px;
    padding-top: 7px;
    line-height: 10px;
    box-sizing: border-box;
    border: none;
}

.cat ul li a:hover {
    color: #059fe2;
}

.catimage:hover~div {
    display: block;
}

.cat:hover {
    display: block;
}

/* 24-1-2024 */

.cat2 {
    /* display: none;
    position: absolute;
    right: -528px;
    top: 0px;
    background: #eee;
    width: 100%;
    height: 360px;
    overflow: scroll; */

    position: absolute;
    right: -272px;
    top: 0px;
    background: #eee;
    width: 100%;
    height: 100%;
    /* height: 360px; */
    overflow-x: auto !important;

}

/* 24-1-2024 */
.activeonhover:hover > .cat2{
    display: block !important;
}

.cat2 ul li a:hover {
    color: #059fe2;
}

.cat2 ul li p {
    font-size: 14px;
    color: #545454;
    font-weight: 400;
    padding-left: 20px;
    padding-top: 7px;
    line-height: 10px;
    box-sizing: border-box;
    border: none;
}

.cat:hover~div {
    display: block;
}

.cat2:hover {
    display: block;
}

.cat2:hover>.cat {
    display: block;
}

/* .cat2:hover .div{
    display: block !important;
}  */

.CategoryName {
    font-size: 54px;
    line-height: 1.10;
    font-weight: 800;
    color: white;
    padding-bottom: 5px;
    position: absolute;
    left: 40px;
    padding-top: 20px;

}

.bluetext {
    padding: 15px;
    /* max-width: 320px; */
    color: #fff;
    background: rgba(5, 159, 226, .8);
    margin-bottom: 15px;
    position: absolute;
    left: 40px;
    height: auto;
    margin-top: 90px;
}

.bluetext .shopbutton {
    font-size: 20px;
    font-weight: 600;
    position: absolute;
    left: 20px;
    color: #fff;
    text-decoration: none;
    /* padding: 13px 22px; */
    display: inline-block;
    margin-top: 20px;
}

/* #root > div > div > section:nth-child(4) > div > div > div > div:nth-child(2) > div.bluetext > div > button:hover{
    color: #059fe2;
} */
#root>div>div>section:nth-child(4)>div>div>div>div:nth-child(2)>div.bluetext>div>button:hover {
    color: #059fe2;
}

.sub-title {
    font-size: 30px;
    line-height: 1.2;
    font-weight: 600;

}

.title {
    font-size: 48px;
    font-weight: 600;
    line-height: 1;
}

.respairator {
    font-size: 54px;
    line-height: 1.10;
    font-weight: 800;
    color: white;
    padding-bottom: 5px;
    position: absolute;
    text-align: right;
    padding-left: 30px;
    padding-top: 20px;
}

.discount {
    font-size: 47px;
    line-height: 1.10;
    font-weight: 600;
    color: white;
    padding-bottom: 5px;
    position: absolute;
    padding-left: 163px;
    padding-top: 89px;
}

.upto {
    font-size: 47px;
    line-height: 1.10;
    font-weight: 700;
    color: white;
    padding-bottom: 5px;
    position: absolute;
    padding-top: 89px;
    padding-left: 30px;
}

.dicountbutton {
    font-size: 20px;
    font-weight: 600;
    position: absolute;
    /* right: 445px; */
    color: #fff;
    background: #059fe2;
    text-decoration: none;
    display: inline-block;
    margin-top: 160px;
    margin-left: 36px;
}

.dicountbutton .btn .btn-primary:hover {
    background: black;
}

.cat3 {
    display: none;
    position: absolute;
    right: -800px;
    top: 0px;
    background: white;
    width: 100%;
    height: 360px;
    overflow-y: scroll;

}

.cat3 ul li a:hover {
    color: #059fe2;
}

.cat3 ul li p {
    font-size: 14px;
    color: #545454;
    font-weight: 400;
    padding-left: 20px;
    padding-top: 7px;
    line-height: 12px;
    box-sizing: border-box;
    border: none;
}

.cat2:hover~div {
    display: block;
}

.cat3:hover {
    display: block;
}


@media screen and (max-width: 768px) {


    .Topbackground {
        height: 350px;
    }
    .cssforcenter{
        justify-content: center;
        padding: 10px 0px;
    }

    .CategoryName {
        font-size: 40px;
    }

    .sub-title {
        font-size: 23px;

    }

    .title {
        font-size: 27px;

    }

    .bluetext {
        padding: 10px;
        margin-top: 70px;
    }



    .respairator {
        font-size: 40px;

    }

    .discount {
        font-size: 37px;
        padding-left: 139px;
        padding-top: 73px;
    }

    .upto {
        font-size: 37px;
        padding-top: 73px;

    }

    .dicountbutton {
        margin-top: 124px;
        margin-left: 32px;
    }

}





@media screen and (max-width: 766px) {

    #root>div>div>section:nth-child(2)>div>div>div>div>div.owl-nav>button.owl-prev span {
        width: 8%;
    }

    #root>div>div>section:nth-child(2)>div>div>div>div>div.owl-nav>button.owl-next span {
        width: 8%;
    }


    #root>div>div>section:nth-child(3)>div>div>div.owl-nav>button.owl-prev span {
        width: 5%;
    }

    #root>div>div>section:nth-child(3)>div>div>div.owl-nav>button.owl-next span {
        width: 5%;
    }


    #root>div>div>section:nth-child(5)>div>div>div.owl-nav>button.owl-prev span {

        width: 5%;

    }

    #root>div>div>section:nth-child(5)>div>div>div.owl-nav>button.owl-next span {

        width: 5%;

    }



    /* .........Eye Protection......... */

    #root>div>div>section:nth-child(6)>div>div>div.owl-nav>button.owl-prev span {
        width: 5%;

    }

    #root>div>div>section:nth-child(6)>div>div>div.owl-nav>button.owl-next span {

        width: 5%;

    }



    /* ..........Hand Protection.......... */

    #root>div>div>section:nth-child(7)>div>div>div.owl-nav>button.owl-prev span {

        width: 5%;

    }

    #root>div>div>section:nth-child(7)>div>div>div.owl-nav>button.owl-next span {

        width: 5%;

    }





    /* ........Head Protection......... */


    #root>div>div>section:nth-child(8)>div>div>div.owl-nav>button.owl-prev span {

        width: 5%;

    }

    #root>div>div>section:nth-child(8)>div>div>div.owl-nav>button.owl-next span {

        width: 5%;

    }

    /* Our Top Brands */
    #root>div>div>section:nth-child(9)>div>div>div.owl-nav>button.owl-prev span {

        width: 5%;

    }

    #root>div>div>section:nth-child(9)>div>div>div.owl-nav>button.owl-next span {
        width: 5%;

    }


    /* Respiratory Protection */
    #root>div>div>section:nth-child(10)>div>div>div.owl-nav>button.owl-prev span {

        width: 5%;

    }


    #root>div>div>section:nth-child(10)>div>div>div.owl-nav>button.owl-next span {
        width: 5%;

    }

    /* Hearing Protection */

    #root>div>div>section:nth-child(11)>div>div>div.owl-nav>button.owl-prev span {
        width: 5%;
    }


    #root>div>div>section:nth-child(11)>div>div>div.owl-nav>button.owl-next span {
        width: 5%;
    }

    /* ......Safety Clothing....... */
    #root>div>div>section:nth-child(12)>div>div>div.owl-nav>button.owl-prev span {
        width: 5%;
    }

    #root>div>div>section:nth-child(12)>div>div>div.owl-nav>button.owl-next span {
        width: 5%;
    }

    .CategoryName {
        font-size: 50px;
    }

    .sub-title {
        font-size: 30px;

    }

    .title {
        font-size: 35px;
        padding-bottom: 15px;

    }

    .bluetext {
        padding: 30px;
        margin-top: 100px;
    }



    .respairator {
        font-size: 50px;

    }

    .discount {
        font-size: 35px;
        line-height: 1.10;
        font-weight: 600;
        color: white;
        padding-bottom: 5px;
        position: absolute;
        padding-left: 139px;
        padding-top: 84px;
    }

    .upto {
        font-size: 30px;
        padding-top: 84px;

    }

    .dicountbutton {
        margin-top: 130px;
        margin-left: 32px;
    }

}

@media screen and (max-width: 600px) {
    /* 29-1-2024 ajay Start */

.item {
    border: 1px solid #e3e3e3;
    /* border: 1px solid gray; */
    /* height: 280px; */
    /* height: 302px; */
    height: 288px;
    align-items: center;
}
    /* 29-1-2024 ajay Start */
    .Topbackground {
        height: 300px;
    }

    #root>div>div>section:nth-child(3)>div>div>div.owl-nav>button.owl-prev span {
        width: 5%;
    }

    #root>div>div>section:nth-child(3)>div>div>div.owl-nav>button.owl-next span {
        width: 5%;
    }


    #root>div>div>section:nth-child(5)>div>div>div.owl-nav>button.owl-prev span {

        width: 5%;

    }

    #root>div>div>section:nth-child(5)>div>div>div.owl-nav>button.owl-next span {

        width: 5%;

    }



    /* .........Eye Protection......... */

    #root>div>div>section:nth-child(6)>div>div>div.owl-nav>button.owl-prev span {
        width: 5%;

    }

    #root>div>div>section:nth-child(6)>div>div>div.owl-nav>button.owl-next span {

        width: 5%;

    }



    /* ..........Hand Protection.......... */

    #root>div>div>section:nth-child(7)>div>div>div.owl-nav>button.owl-prev span {

        width: 5%;

    }

    #root>div>div>section:nth-child(7)>div>div>div.owl-nav>button.owl-next span {

        width: 5%;

    }





    /* ........Head Protection......... */


    #root>div>div>section:nth-child(8)>div>div>div.owl-nav>button.owl-prev span {

        width: 5%;

    }

    #root>div>div>section:nth-child(8)>div>div>div.owl-nav>button.owl-next span {

        width: 5%;

    }

    /* Our Top Brands */
    #root>div>div>section:nth-child(9)>div>div>div.owl-nav>button.owl-prev span {

        width: 5%;

    }

    #root>div>div>section:nth-child(9)>div>div>div.owl-nav>button.owl-next span {
        width: 5%;

    }


    /* Respiratory Protection */
    #root>div>div>section:nth-child(10)>div>div>div.owl-nav>button.owl-prev span {

        width: 5%;

    }


    #root>div>div>section:nth-child(10)>div>div>div.owl-nav>button.owl-next span {
        width: 5%;

    }

    /* Hearing Protection */

    #root>div>div>section:nth-child(11)>div>div>div.owl-nav>button.owl-prev span {
        width: 5%;
    }


    #root>div>div>section:nth-child(11)>div>div>div.owl-nav>button.owl-next span {
        width: 5%;
    }

    /* ......Safety Clothing....... */
    #root>div>div>section:nth-child(12)>div>div>div.owl-nav>button.owl-prev span {
        width: 5%;
    }

    #root>div>div>section:nth-child(12)>div>div>div.owl-nav>button.owl-next span {
        width: 5%;
    }


    .sub-title {
        font-size: 38px;

    }

    .title {
        font-size: 32px;


    }

    .bluetext {
        padding: 20px;
        margin-top: 80px;
    }

}

@media screen and (max-width: 500px) {

    .Topbackground {
        height: 280px;
    }


    #root>div>div>section:nth-child(1)>div>div>div>div>div.owl-nav>button.owl-prev span {

        width: 7%;

    }

    #root>div>div>section:nth-child(1)>div>div>div>div>div.owl-nav>button.owl-next span {

        width: 7%;

    }

    #root>div>div>section:nth-child(3)>div>div>div.owl-nav>button.owl-prev span {
        width: 8%;
    }

    #root>div>div>section:nth-child(3)>div>div>div.owl-nav>button.owl-next span {
        width: 8%;
    }


    #root>div>div>section:nth-child(5)>div>div>div.owl-nav>button.owl-prev span {

        width: 8%;

    }

    #root>div>div>section:nth-child(5)>div>div>div.owl-nav>button.owl-next span {

        width: 8%;

    }



    /* .........Eye Protection......... */

    #root>div>div>section:nth-child(6)>div>div>div.owl-nav>button.owl-prev span {
        width: 8%;

    }

    #root>div>div>section:nth-child(6)>div>div>div.owl-nav>button.owl-next span {

        width: 8%;

    }



    /* ..........Hand Protection.......... */

    #root>div>div>section:nth-child(7)>div>div>div.owl-nav>button.owl-prev span {

        width: 8%;

    }

    #root>div>div>section:nth-child(7)>div>div>div.owl-nav>button.owl-next span {

        width: 8%;

    }





    /* ........Head Protection......... */


    #root>div>div>section:nth-child(8)>div>div>div.owl-nav>button.owl-prev span {

        width: 8%;

    }

    #root>div>div>section:nth-child(8)>div>div>div.owl-nav>button.owl-next span {

        width: 8%;

    }

    /* Our Top Brands */
    #root>div>div>section:nth-child(9)>div>div>div.owl-nav>button.owl-prev span {

        width: 8%;

    }

    #root>div>div>section:nth-child(9)>div>div>div.owl-nav>button.owl-next span {
        width: 8%;

    }


    /* Respiratory Protection */
    #root>div>div>section:nth-child(10)>div>div>div.owl-nav>button.owl-prev span {

        width: 8%;

    }


    #root>div>div>section:nth-child(10)>div>div>div.owl-nav>button.owl-next span {
        width: 8%;

    }

    /* Hearing Protection */

    #root>div>div>section:nth-child(11)>div>div>div.owl-nav>button.owl-prev span {
        width: 8%;
    }


    #root>div>div>section:nth-child(11)>div>div>div.owl-nav>button.owl-next span {
        width: 8%;
    }

    /* ......Safety Clothing....... */
    #root>div>div>section:nth-child(12)>div>div>div.owl-nav>button.owl-prev span {
        width: 8%;
    }

    #root>div>div>section:nth-child(12)>div>div>div.owl-nav>button.owl-next span {
        width: 8%;
    }




    .sub-title {
        font-size: 23px;

    }

    .CategoryName {
        font-size: 40px;
    }

    .title {
        font-size: 22px;


    }

    .bluetext {
        padding: 15px;
        margin-top: 68px;
    }

    .respairator {
        font-size: 50px;

    }

    .discount {
        font-size: 35px;
        line-height: 1.10;
        font-weight: 600;
        color: white;
        padding-bottom: 5px;
        position: absolute;
        padding-left: 139px;
        padding-top: 84px;
    }

    .upto {
        font-size: 30px;
        padding-top: 84px;

    }

    .dicountbutton {
        margin-top: 130px;
        margin-left: 32px;
    }

}

@media screen and (max-width: 425px) {


    #root>div>div>section:nth-child(1)>div>div>div>div>div.owl-nav>button.owl-prev span {

        width: 7%;

    }

    #root>div>div>section:nth-child(1)>div>div>div>div>div.owl-nav>button.owl-next span {

        width: 7%;

    }

    .Topbackground {
        height: 250px;
    }

    .BestSelling {
        font-size: 21px;
    }

    .sub-title {
        font-size: 20px;

    }

    .CategoryName {
        font-size: 30px;
    }

    .title {
        font-size: 20px;


    }

    .bluetext {
        padding: 10px;
        margin-top: 57px;
    }

    .respairator {
        font-size: 30px;

    }

    .discount {
        font-size: 25px;
        padding-top: 59px;
        padding-left: 92px;

    }

    .upto {
        font-size: 20px;
        padding-top: 64px;

    }

    .dicountbutton {
        margin-top: 101px;
        margin-left: 32px;
    }



}

@media screen and (max-width: 375px) {

    .Topbackground {
        height: 230px;
    }

    .CategoryName {
        font-size: 25px;
    }

    .sub-title {
        font-size: 16px;

    }

    #root>div>div>section:nth-child(3)>div>div>div.owl-nav>button.owl-prev span {
        width: 10%;
    }

    #root>div>div>section:nth-child(3)>div>div>div.owl-nav>button.owl-next span {
        width: 10%;
    }


    #root>div>div>section:nth-child(5)>div>div>div.owl-nav>button.owl-prev span {

        width: 10%;

    }

    #root>div>div>section:nth-child(5)>div>div>div.owl-nav>button.owl-next span {

        width: 10%;

    }



    /* .........Eye Protection......... */

    #root>div>div>section:nth-child(6)>div>div>div.owl-nav>button.owl-prev span {
        width: 10%;

    }

    #root>div>div>section:nth-child(6)>div>div>div.owl-nav>button.owl-next span {

        width: 10%;

    }



    /* ..........Hand Protection.......... */

    #root>div>div>section:nth-child(7)>div>div>div.owl-nav>button.owl-prev span {

        width: 10%;

    }

    #root>div>div>section:nth-child(7)>div>div>div.owl-nav>button.owl-next span {

        width: 10%;

    }





    /* ........Head Protection......... */


    #root>div>div>section:nth-child(8)>div>div>div.owl-nav>button.owl-prev span {

        width: 10%;

    }

    #root>div>div>section:nth-child(8)>div>div>div.owl-nav>button.owl-next span {

        width: 10%;

    }

    /* Our Top Brands */
    #root>div>div>section:nth-child(9)>div>div>div.owl-nav>button.owl-prev span {

        width: 10%;

    }

    #root>div>div>section:nth-child(9)>div>div>div.owl-nav>button.owl-next span {
        width: 10%;

    }


    /* Respiratory Protection */
    #root>div>div>section:nth-child(10)>div>div>div.owl-nav>button.owl-prev span {

        width: 10%;

    }


    #root>div>div>section:nth-child(10)>div>div>div.owl-nav>button.owl-next span {
        width: 10%;

    }

    /* Hearing Protection */

    #root>div>div>section:nth-child(11)>div>div>div.owl-nav>button.owl-prev span {
        width: 10%;
    }


    #root>div>div>section:nth-child(11)>div>div>div.owl-nav>button.owl-next span {
        width: 10%;
    }

    /* ......Safety Clothing....... */
    #root>div>div>section:nth-child(12)>div>div>div.owl-nav>button.owl-prev span {
        width: 10%;
    }

    #root>div>div>section:nth-child(12)>div>div>div.owl-nav>button.owl-next span {
        width: 10%;
    }

    .item {
        height: 420px;
    }

    .title {
        font-size: 18px;


    }

    .bluetext {
        padding: 6px;
        margin-top: 48px;
    }

    .BestSelling {
        font-size: 16px;
    }
}

/* Privacy Policy Page Css */

.ct_privacy_para {
    font-family: Barlow, sans-serif;
    line-height: 28px;
    font-size: 16px;
    margin-bottom: 15px;

}

.bold {
    font-weight: 600;
    padding-top: 20px;
}

.privacysmalltext {
    padding-top: 20px;

}

.info {
    font-weight: 600;
    /* padding-top: 20px; */
}

.infotext {
    padding-top: 20px;
    padding-bottom: 15px;
}

.info2 {
    padding-top: 20px;
    font-weight: 600;
}

.main-header .header-cart a img {
    padding-right: 121px;
}



.header-cart span.cart-heading {
    padding-right: 120px;
}
/* .....PRODUCTS PAGE POPUP CSS..... */

.popup-text{
    font-size: 30px;
    font-weight: 700;
    color: #333;

 }

 .Sku-text{
    color: #666;
    font-size: 16px;
    line-height: 32px;
    padding-right: 25px;
    position: relative;
 }

  .action a{
    display: flex;
    flex-direction: column;
    padding: 14px 38px;
    font-size: 13px;
    color: #a5a5a5;
    text-transform: uppercase;
    position: relative;

 } 
 .action{
    display: flex;
    /* border-top: 1px solid #eee; */
    /* border-bottom: 1px solid #eee; */
    margin: 10px 0;

 }


 .action a{
    display: flex;
    flex-direction: column;
    /* padding: 14px 38px; */
    font-size: 13px;
    color: #a5a5a5;
    text-transform: uppercase;
    position: relative;
 }

 .action .share1{
    font-size: 11px;
    padding-left:0px ;
    padding-top: 10px;
    padding-bottom: 15px;
  
 }

 .action .wish{
    font-size: 13px;
    padding-left:50px ;
    padding-top: 13px;
    padding-bottom: 15px;
 }

 .share-img{
    margin-top: 7px;
 }

 .price1{
    padding: 17px 0 24px;
    border-bottom: 1px solid #eee;
 }

 .price1 label{
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    padding-left: 10px;

 }

 .price1 p{
    font-size: 20px;
     color: #059fe2;;
    display: inline-block;
    padding-left: 7px;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    text-transform: uppercase;

 }
 .smallaed{
    text-decoration: line-through;
    padding-left: 5px;
    font-size: 15px;
    color: #a5a5a5;
 }

 .order-now-btn button{
    color: #fff;
    background: #059fe2;
    width: 100%;
    display: flex;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 900;
    justify-content: center;
    align-items: center;
    padding: 15px 10px;
    position: relative;
    border-color: #059fe2;

 }
 .order-now-btn{
    margin-top: 22px;
 }

 .order-now-btn button:hover{
    background: black;
    border: none;
 }
 .count{
    margin: 0 4px;
    padding: 9px 15px 9px 15px;
    font-size: 18px;
    font-weight: 600;
    background: #fff;
    color: #2a2a2a;
    border-color: #059fe2;
}

.onecount{
    background-color: #059fe2;
    color: #fff;
    padding: 9px 15px 9px 15px;
    border: 1px solid #059fe2;
}
.count1{
    padding: 9px 15px 9px 15px;
    height: 38px;
    min-width: 38px;
    border: 0;
    background: #2a2a2a;
    position: relative;
   
}
@media only screen and (max-width: 770px) {
    .popup-text{
        font-size: 22px;
           
     }
 }





















 @media screen and (max-width: 575px) {
    .search-with-cat.search-width form .inner-form .input-field.first-wrap {
        min-width: 90% !important;
    }
}



@media screen and (max-width: 768px) {

    .search-with-cat.search-width form .inner-form .input-field.first-wrap {
        min-width: 80% !important;
    }
}




.search-with-cat form .inner-form .input-field input {
    height: 100%;
    background: #fbfbfb;
    display: block;
    width: 101%;
    padding: 10px 10px;
    font-size: 16px;
    color: #8f8f8f;
    border: 1px solid #e5e5e5;
}


.search-with-cat form .inner-form .input-field .btn-search {
    height: 100%;
    width: 101%;
    margin-right: 20px;
    white-space: nowrap;
    font-size: 20px;
    color: #fff;
    border: 0;
    cursor: pointer;
    position: relative;
    z-index: 0;
    background: #060407;
    transition: all .2s ease-out, color .2s ease-out;
    font-weight: 500;
}



@media only screen and (max-width: 767px) {
    .main-header .search-with-cat {
        bottom: -58px;
    }
}

@media only screen and (max-width: 992px) {
    .box1 {
        padding: 20px;
        background: #fff;
        border: 1px solid #ececec;
        margin-bottom: 5px;
        /* width: 100px; */
    }

    .Topbackground {
        height: 370px !important;
    }

    .sb_topfooter {
        width: 100%;
    }
    .rightsidefooter > h4 {
        text-align: center;
    }
    .rightsidefooter > p {
        text-align: center;
        padding-bottom: 9px;
    }
    .products {
        background: none;
        height: auto;
        /* border: 1px solid #ececec; */ 
        padding: 6px 0px;
        border-bottom: 1px solid rgba(128, 128, 128, 0.441);
    }
    .responsivetitle{
        justify-content: center;
    }
    .responsivetitle2{
        display: flex;
        justify-content: center;
    }
    .extramargin21{
        margin-left: 3rem;
        margin-right: 3rem;
    }
}





@media only screen and (max-width: 768px) {
    header .main-header .logo img {
        max-width: 106px;
    }


    .col-md-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;

    }

}



.post-block1 {
    display: flex;
    border: 1px solid #d8d8d8;
    padding: 5px;
    margin-bottom: 15px;
}


.post-block1 img {
    width: 80%;
    min-width: 235px;
    max-width: 235px;
}


.comment-block1 h2 {
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    color: #333333;
    padding-left: 20px;
    padding-bottom: 15px;
    padding-top: 25px;
}

.entry-content1 {
    padding-left: 20px;
    font-size: 18px;
    line-height: 24px;
    color: #565656;
    padding-bottom: 20px;
}

.post-footer1 {
    padding-top: 40px;
}

.post-date1 {
    color: #999;
    font-size: 13px;
    line-height: 24px;
    padding-left: 20px;
}

.post-date1,
.post-footer1 .social-share {
    padding-bottom: 10px;
    color: #999;
    font-size: 13px;
    line-height: 24px;
}


.post-footer1 .post-meta .post-date1,
.post-footer1 .social-share {
    color: #999;
    font-size: 13px;
    line-height: 24px;
}

.social-share {
    display: flex;
}

.post-meta {
    display: flex;
    justify-content: space-between !important;
}

.popular-post-block .block-title {
    padding-bottom: 40px;
    font-size: 20px;
    font-weight: 600;
    color: #2f2f2f;
    border-bottom: 2px solid #e1e1e1;
    padding-bottom: 18px;


}


.popular-post1-block .block-title {
    padding-bottom: 40px;
    font-size: 20px;
    font-weight: 600;
    color: #2f2f2f;
    border-bottom: 2px solid #e1e1e1;
    padding-bottom: 18px;


}













.maindiv {
    display: flex;
}


.popular-tags {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    background: rgba(5, 159, 226, .1);
    padding: 13px 17px;
    color: #059fe2;
    display: inline-block;
    margin: 5px 5px 5px 0;
    float: left;

}

.popular-tag1 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    background: rgba(5, 159, 226, .1);
    padding: 13px 17px;
    color: #059fe2;
    display: inline-block;
    margin: 5px 5px 5px 0;


}

.remove-button {
    -bs-btn-close-bg: none !important;
}

@media only screen and (max-width: 1408px) {
    .menuicon {
        display: none !important;
    }
}

@media only screen and (max-width: 1308px) {
    .Topbackground {
        height: 420px !important;
    }
    .extramargin21{
        margin-left: 5rem;
        margin-right: 5rem;
    }
}





/* ........ ADDRESS PAGE CSS........ */

.hand1 {
    display: inline-flex;
    width: 100%;
    border: 1px solid #ececec;
    background-color: #059fe2;
    padding: 20px;
}

 .address3{
    color: black;
    font-size: 16px;
    font-weight: 500;
}
.add2:hover .address3{
   color: #fff !important;
}

.add2:hover .icplus {
    color: white !important;
}
.edititem{
    left: -148px !important;
    top: -22px !important;
}

.cattext1 a {
    color: white;
    font-size: 20px;
}

.userIcon {
    color: black;
}

/* .invoimg{
    background:#059fe2 ;
} */

.hand>.cattext a:hover {
    color: white;
}
/* .hand>.cattext a img {
   background-color: green;
} */
.hand:hover> a img {
   background-color: #fff;
   /* background-color: #059fe2; */
}

.addressbox {
    border: 1px solid #e6e6e6;
    background: #fff;
    padding: 22px;
    align-self: flex-start;
    margin-top: 17px;
}

.add {
    font-size: 21px;
    font-weight: 600;
    color: #262626;
    border-bottom: 1px solid #ededed;
    padding-bottom: 20px;
    margin-bottom: 20px;

}

.add2 {
    display: inline-block;
    font-size: 16px;
    color: #000;
    font-weight: 600;
    text-transform: uppercase;
    border: 2px solid #059fe2;
    padding: 12px 18px 12px 18px;
    position: relative;
}

.add2:hover {
    background: #059fe2;
    color: white;
}

.add2 .icplus {
    color: #059fe2;
}


.add2:hover>.icplus {
    color: white;
}


.addcard {
    background: #f9f9f9;
    border: 1px solid #e2e2e2;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 30px;
    padding-right: 50px;
    position: relative;
    margin-bottom: 20px;
    margin-top: 20px;
}

.addHeading {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}

.address-content {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #4c4c4c;
}

.threedot {
    position: absolute;
    right: 15px;
}



.hand {
    display: inline-flex;
    width: 100%;
    border: 1px solid #ececec;
    background-color: white;
    padding: 16px 22px;

}


.hand:hover {
    background: #059fe2;
    /* color: white; */
}

.hand:hover>.cattext a {
    color: white;

}

.hand:hover>.cattext .invoimg {
    background: white;

}


.hand .cattext a {
    color: #000;
    font-size: 18px;
    font-weight: 500;
display: flex;
gap: 14px;
}

#primary .tool-bar select {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    padding: 12px 18px 12px 40px;
}


#primary .tool-bar .dropdown-box:after {
    content: "\f078";
    font-family: "FontAwesome";
    font-weight: 600;
    color: #6d6d6d;
    position: absolute;
    right: 10px;
    line-height: 12px;
    top: 17px;
    font-size: 17px;

}

#primary .tool-bar label {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    padding-bottom: 15px;

}



/* .........BULK-ORDER PAGE MEDIA....... */

.firstinput {
    width: 30%;
}

.longinput {
    width: 60%;
}

.bulkorder-heading h3 {
    font-weight: 600;
    color: #262626;
    font-size: 26px;
}

.bulkorder-heading p {
    font-weight: 400;
    color: #565656;
    font-size: 18px;
}




@media only screen and (max-width: 500px) {
    .firstinput {
        width: 42%;
    }

    .longinput {
        width: 85%;
    }

    .bulkorder-heading h3 {
        font-size: 23px;
    }

    .bulkorder-heading p {

        font-size: 16px;
    }
}

/* ............MY ITEMS PAGE CSS............. */


.myItems {
    font-size: 21px;
    font-weight: 600;
    color: #262626;
    border-bottom: 1px solid #ededed;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.unitText{
    margin-right: 10px;
}
.skutext{
    padding-left:45px;
}

.Myitems-box {
    border: 1px solid #e6e6e6;
    background: #fff;
    padding: 22px;

}

/* ......WHISHLIST PAGE CSS....... */

.imageheart1 {
    font-size: 30px;
    padding-right: 20px;
    color: rgba(128, 128, 128, 0.548);
    position: absolute;
    top: 26px;
    right: 0px;
    display: flex;
    justify-content: right;
    flex-direction: column;
    opacity: 0;
    transition: 0.6s;

}

.rightone:hover>.imageheart1 {
    color: #059fe2;
    opacity: 1;
}




/* ...........PASSWORD PAGE CSS............ */

.curpassword {
    font-size: 16px;
    line-height: 32px;

}

#product-listing-sec>div>div>div.col-lg-9.col-sm-12.Myitems-box>div>div>input {
    border: 1px solid #dadada;
    border-radius: 0;
    padding: 0 15px;
    color: #000;
    margin-bottom: 10px;
    height: 45px;
    width: 40%;
}

.action-btn {
    width: 40%;
    text-align: right;

}

#send_otp {
    border: 2px solid #059fe2;
    border-radius: 0;
    background: white;
    color: #059fe2;
    margin-bottom: 10px;
    height: 45px;
    width: 40%;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 12px 18px;
    transition: all .2s ease-in-out;
}

#send_otp:hover {
    background: #059fe2;
    color: white;
}

@media only screen and (max-width: 768px) {

    #product-listing-sec>div>div>div.col-lg-9.col-sm-12.Myitems-box>div>div>input {
        width: 50%;
    }

    .action-btn {
        width: 50%;
    }

    #send_otp {
        width: 47%;
    }

}


@media only screen and (max-width: 600px) {

    #product-listing-sec>div>div>div.col-lg-9.col-sm-12.Myitems-box>div>div>input {
        width: 47%;
    }

    .action-btn {
        width: 47%;
    }

    /* #send_otp{
            width: 48%; 
        } */

}

@media only screen and (max-width: 500px) {

    #product-listing-sec>div>div>div.col-lg-9.col-sm-12.Myitems-box>div>div>input {
        width: 70%;
    }

    .action-btn {
        width: 70%;
    }

    #send_otp {
        width: 47%;
    }

}


@media only screen and (max-width: 425px) {

    #product-listing-sec>div>div>div.col-lg-9.col-sm-12.Myitems-box>div>div>input {
        width: 80%;
    }

    .action-btn {
        width: 80%;
    }

    #send_otp {
        width: 47%;
    }

}




/* ...........MY-RFQ PAGE CSS............. */
.order-details {
    border-bottom: 1px solid #dee2e6 !important;
}

.order-details-heading {
    font-weight: 600;
    font-size: 15px;

}

.created {
    color: #87ceeb;
    font-weight: 600;
    font-size: 15px;

}


/* ...........Profile page css............. */
.order-details-heading1 {
    font-size: 14px !important;
    color: #666;
}

.imgView {
    height: 150px;
    width: 270px;
    padding-bottom: 15px;
}

.Vendercode {
    padding-top: 10px;
    font: inherit;
    vertical-align: baseline;
    padding-bottom: 20px;
}

.profile-infobox {
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 12px 0;

}


.btn-btn-save {
    background-color: #059fe2;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    transition: all .4s ease-in-out;
    padding-left: 15px;
    padding-right: 15px;

}

.btn-btn-save:hover {
    background: black;
    color: #fff;
}



@media only screen and (max-width: 375px) {
    .Vendercode {
        font-size: 14px;
    }
}



/* ...........Cart page css......... */

.hedding-text h2 {
    font-weight: 700;
    color: #282828;
    font-size: 35px;
    line-height: 40px;
    background-color: #FBFBFB;
    padding: 2px 22px;
    position: relative;
    z-index: 1;
}






.product-block {
    background: #fff;
    border: 1px solid #e6e6e6;
    margin-bottom: 10px;
    display: flex;

}

.left-part {
    /* padding: 12px 15px; */
    width: 75%;
    align-items: center;
    display: flex;
}

.pro-name {
    font-size: 14px;
    font-weight: 700;
    color: #333;
    line-height: 26px;
}


.qty {
    padding-right: 40px;
}

.total-price {
    background-color: #f6f6f6;
    padding: 10px 25px;
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin: 2px;
   
}
.center-price {
    padding-top: 85px;
}
.deleteimg {
    background: #fff;
    font-size: 14px;
    border: 2px solid #f1f1f1;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #059fe2;
    position: absolute;
    top: 19px;
    right: -91px;
  
}

.lebal {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 32px;

}

.value {
    font-size: 19px;
    color: #059fe2;
}

.payment-summary {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    margin-bottom: 20px;
}

.total-amount {
    background: #f6f6f6;
    padding-top: 10px;
    padding-bottom: 10px;
}

.total-amount p {
    font-size: 16px;
    line-height: 26px;
    display: flex;
    justify-content: space-between;
}

.total-amount p strong {
    font-weight: 600;
}

.t-strong {
    text-transform: capitalize !important;
    font-size: 15px;
}

.payment-summary .plr-20 {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.header-part h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;

}

.total-content p {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    justify-content: space-between;
}

.total-content p strong {
    font-weight: 600;
}

.amount-payable p {
    line-height: 26px;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.amount-payable p strong {
    color: #059fe2;
    font-weight: 700;
    text-transform: uppercase;
}

.proceed-to-check .check-button {
    font-size: 20px;
    font-weight: 600;
    background: #059fe2;
    color: #fff;
    padding: 18px 30px;
    display: block;
    text-align: center;
    margin-left: auto;
}

.proceed-to-check {
    text-align: center;
    margin: 20px 0;
}

.qty-button {
    width: 54px;
    height: 22px;
    text-align: center;
    font-weight: 500;
    border: 1px solid #e7e7e7;
}

.product-img {
    /* vertical-align: middle;
    box-sizing: border-box;
    max-width: 200px;
    width: 100%; */
    vertical-align: middle;
    box-sizing: border-box;
    max-width: 200px;
    width: 100%;
    padding-top: 10px;

}

.imgcard {
    border: none;
    align-items: center;
    /* height: 210px; */
    padding-top: 10px;

}

.imgcard1 {
    border: none;
    padding-top: 40px;
    
}

.imgcard2 {
    /* border: none;
    text-align: center; */
    border: none;
    text-align: center;
    /* background-color: #f6f6f6; */
    /* padding-top: 107px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin: 2px;

}

.pro-cat {
    /* color: #059fe2;
    font-size: 13px; */
    font-size: 12px;
    font-weight: 600;
    color: #059fe2;
    line-height: 26px;
    text-transform: uppercase;
}


@media only screen and (max-width: 325px) {
    .hedding-text h2 {

        font-size: 20px;

    }

    .proceed-to-check .check-button {
        font-size: 15px;
    }
}





/* LOGIN PAGE CSS */
.login-box {
    position: relative;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 0.3rem;
    outline: 0;
    background: white;

}

.close {
    position: absolute;
    right: 0;
    background: #059fe2;
    top: 0;
    height: 36px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    border: none;
    text-shadow: none;
    z-index: 1;
}

.btn-close {

    border-bottom: none;
}

.icon i.fa {
    position: absolute;
    right: 72px;
    bottom: 33px;
    font-size: 12px;
}

.sign-text {
    font-weight: 700;
    color: #000;
    font-size: 26px;
    line-height: 32px;

}

.input-areabutton {
    border: none;
    width: 80%;
    align-items: center;
    border-bottom: 1px solid #ced4da;
    border-radius: 0;
    /* background-color:rgb(232, 240, 254); */
    box-shadow: none;
    padding: 0;
    height: 45px;
    font-weight: 400;
    font-size: 16px;
    padding-left: 10px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

}

.forgot a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    margin-left: auto;
    padding-left: 32px;
    padding-bottom: 20px;
    font-size: 15px;
    /* font-family: "sans-serif "; */
    font-family: Barlow,sans-serif;
}

.forgot a:hover {
    color: #007bffa8;

}

.forgot1 a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    margin-left: auto;
    position: relative;
    left: -24px;
    padding-left: 80px;
    padding-bottom: 20px;
    font-size: 15px;
    /* font-family: "sans-serif "; */
    font-family: Barlow,sans-serif;

}

.forgot1 a:hover {
    color: #007bffa8;

}

.signup-text h6 {
    font-weight: 500;
    color: #000;
    font-size: 18px;
    line-height: 26px;
    transition: all .5s ease;

}

.signup-text h6 a {
    font-weight: 700;
    color: #059fe2;
}

.signup-text span {
    font-weight: 400;
    color: #141414;
    font-size: 14px;
    line-height: 20px;
    transition: all .5s ease;
}

.signup-text span a {
    color: #059fe2;
    font-weight: 600;
}




.modal-header {
    border-bottom: none;
}





@media only screen and (max-width: 375px) {
    .input-areabutton {
        font-size: 15px;
    }

    .forgot a {
        padding-left: 28px;
        font-size: 13px;
    }

    .forgot1 a {
        padding-left: 24px;
        font-size: 13px;
    }

    .icon i.fa {
        right: 53px;
        bottom: 30px;

    }
}


/* ........... MY ORDERS PAGE CSS.............. */

.Myorder {
    font-size: 21px;
    font-weight: 600;
    color: #262626;
    border-bottom: 1px solid #ededed;
    padding-bottom: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-left: 15px;
}

.order1 {
    background: #fff;
    border: 1px solid #e3e3e3;
    margin-bottom: 0;
    display: flex;
    width: 100%;

}

.orderId {
    font-size: 30px;
    font-weight: 600;
    color: #333;
    padding-top: 20px;
    padding-right: 50px;

}

.cash-delivery {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    color: #059fe2;
    line-height: 26px;
    padding: 5px 0 0;
}

.order-date {
    font-size: 14px;
    color: #474747;
    line-height: 23px;
    font-weight: 300;
}

.totalprice {
    display: flex;
    align-items: center;
    padding: 10px 0 0
}

.total {
    font-size: 20px;
    font-weight: 500;
    color: #333;

}

.total span {
    color: #059fe2;
    font-weight: 700;

}

.full-order a {
    display: inline-block;
    padding: 13px 22px;
    background: #059fe2;
    color: #fffefe;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    margin-left: 20px;

}

.rightPart {
    background-color: #f6f6f6;
    padding: 10px 25px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 1px;
}

.status-value {
    font-size: 21px;
    font-weight: 700;
    color: #059fe2;
    padding: 10px 0;
}

.status-label {
    font-size: 13px;
    color: #a9a9a9;
    font-weight: 600;
}

/* .order-block{
    margin-top: 50px;
} */



@media only screen and (max-width: 992px) {
    .orderId {
        text-align: center;

    }

    .total {
        text-align: center;
        padding-bottom: 15px;
    }

    .totalprice {
        align-items: center;
        padding-bottom: 20px;
        flex-direction: column;

    }

    .order-block {
        margin-top: 50px;
    }

}

@media only screen and (max-width: 768px) {
    .orderId {
        text-align: center;

    }

    .total {
        text-align: center;
        padding-bottom: 15px;
    }

    .totalprice {
        align-items: center;
        padding-bottom: 20px;
        flex-direction: column;

    }

    .order-block {
        margin-top: 50px;
    }

}

@media only screen and (max-width: 375px) {
    .orderId {
        /* text-align: center; */
        font-size: 22px;

    }

    .cash-delivery {
        font-size: 14px;

    }

    .order-date {
        font-size: 13px;
        text-align: center;
    }

    .total {
        text-align: center;
        padding-bottom: 15px;
        font-size: 19px;
    }

    .full-order a {
        font-size: 14px;
    }

}

@media only screen and (max-width: 375px) {
    .hedding-text h2 {
        font-size: 29px;

    }



    .proceed-to-check .check-button {
        font-size: 18px;
    }
}



.form-row .inputarea{
    border: none;
    border-bottom: 1px solid #ddd;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
    padding: 0;
    /* height: 45px;
    font-weight: 400;
    font-size: 16px;
    color: #000; */
}

/* .form-group {
    margin-bottom: 1rem;
} */



/* start arpit css */

table, td, th {  
    /* border: 1px solid #ddd; */
    text-align: left;
  }
  
  table {
    border-collapse: collapse;
    width: 100%;
  }
  
  th, td {
    padding: 15px;
  }
  /* End table css */
  .checkbox-col input[type=checkbox]
  {
    /* width: 1.3em;
    height: 1.3em; */
    width: 19px;
    height: 19px;
    background-color: gray;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    /* margin-top: -3px; */
    margin-top: -2px;
  }
  .checkbox-col input[type=checkbox]:checked {
    background-color: #007bff;
}

/* pagination for project list */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 20px 0;
  }
  
  .pagination a {
    display: inline-block;
    padding: 10px;
    margin: 0 5px;
    text-decoration: none;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  
  .pagination a:hover {
    background-color: #f5f5f5;
  }
  
  
  .pagination .active {
    background-color: #059fe2;
    /* background-color: transparent;  */
    color: #333; /* Change text color for the active page */
    border: none; /* Remove border */
  }
  .pagination .inactive {
    background-color: #eee;
    color: #777;
    border: 1px solid #ddd;
  }
 /* end product list pagination   */
 /* start validation error css */
 .error{
    color:red;
 }
 /* end validation error css */

 /* start alert after submit form
  */
  .alert {
	margin-right: 15px;
    margin-left: 15px;
	padding:12px;
	border-radius: 5px;

	font-family: 'Open Sans', sans-serif;
	font-size: .9rem;
	font-weight: 300;
	letter-spacing: 1px;
}



.alert-adress {
	margin-right: 15px;
	padding:12px;
	border-radius: 5px;
    margin-top: 7px;

	font-family: 'Open Sans', sans-serif;
	font-size: .9rem;
	font-weight: 300;
	letter-spacing: 1px;
}

.alert:hover {
  cursor: pointer;
}

.alert:before {
	padding-right: 12px;
}
  
/* .alert:after {
  content: '\f00d';
  font-family: 'FontAwesome';
  float: right;
  padding: 3px;
    &:hover {
      cursor: pointer;
    }
} */

.alert-info {
  color: #00529B;
  background-color: #BDE5F8;
  border: 1px solid darken(#BDE5F8, 15%);
}

.alert-info:before {
  content: '\f05a';
  font-family: 'FontAwesome';
}

.alert-warn {
  color: #9F6000;
  background-color: #FEEFB3;
  border: 1px solid darken(#FEEFB3, 15%);
}

.alert-warn:before {
  content: '\f071';
  font-family: 'FontAwesome';
}

.alert-error {
  color: #D8000C;
  background-color: #FFBABA;
  border: 1px solid darken(#FFBABA, 15%);
}

/* .alert-error:before {
  content: '\f057';
	font-family: 'FontAwesome';
} */

.alert-success {
    display: flex;
       padding-left: 10px;
     color: #155724;
     background-color: #d4edda;
     border: 1px solid darken(#DFF2BF, 15%);
   }

/* .alert-success:before {
  content: '\f058';
	font-family: 'FontAwesome';
} */
/* end alert msg css */

/* loader css all pages */

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
/* end loader css all pages */
.order-block {
    border: 1px solid #e6e6e6;
    background: #fff;
    padding: 22px;
}
.label {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 32px;
}
.value {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: #059fe2;
    line-height: 32px;
}
.order-now {
    font-size: 15px;
    line-height: 20px;
    text-transform: uppercase;
    color: #464646;
    font-weight: 600;
    padding: 10px 29px;
    border: 0px solid;
    display: inline-block;
    margin: auto;
    margin-top: 14px;
    background: #059fe2;
    border-color: #059fe2;
    color: #fff;
}
.title_ord_pri {
    font-size: 21px;
    font-weight: 600;
    color: #262626;
    border-bottom: 1px solid #ededed;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.text-right
{
  text-align: right;
}
.video-title-video {
    background: #f6f6f6;
    text-align: center;
    font-size: 18px;
    color: #151515;
    font-weight: 500;
    padding: 18px 6px;
    border: 1px solid #ececec;
    border-top: none;
    margin-top: 61px!important;
    /* margin: 3px; */
    margin-bottom: 0px;
    width: 320px;
}
.video-title-height {
    min-height: 70px;
}
.action-button a {
    font-size: 17px;
    line-height: 20px;
    text-transform: uppercase;
    color: #464646;
    font-weight: 600;
    padding: 11px 20px;
    border: 2px solid;
    display: inline-block;
    margin: 0 1px;
}
.in-process {
    background: grey;
    border-color: grey;
    color: #fff!important;
    width: 100%;
    margin: 0;
    text-align: center;
}
.video-block{
    margin-bottom: -45px;
}
.playpause {
    margin-top: -153px;
}
.playpause a {
    font-size: 20px;
    display: inline-flex;
    background: #059fe2;
    border-radius: 50%;
    line-height: 25px;
    width: 55px;
    min-height: 55px;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.bdr-ripple-ani-btn{
    text-align: center;
    box-sizing: border-box;
    -webkit-animation: at-ripple .6s linear infinite;
    animation: at-ripple .6s linear infinite;
    overflow: hidden;
}
.fa .fa-play{
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: var(--fa-display, inline-block);
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-rendering: auto;
}
.sub-category-list{
    margin-bottom: 15px;
    margin-right: 2.25%;
}
.sub-category-item {
    background: #fff;
    padding: 25px 20px;
    width: 16.75%;
    margin-right: 20px;
    display: inline-block;
    text-align: center;
    border-radius: 7px;
    box-shadow: 0 8px 6px rgba(0,0,0,.02);
    transition: all .2s ease-in-out;
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 40px;
}
.sub-category-item:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, .3); 
}
/* start bulk order search input css */
.autocomplete-container {
    position: relative;
  }
  
  .autocomplete-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
  }
  
  .autocomplete-suggestions {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    /* width: 424px !important; */
    width: 752px !important;
    /* background-color: #fff; */
    border: 1px solid #ccc;
    border-top: none;
    max-height: 150px;
    overflow-y: auto;
    z-index: 99;
    background: #fbfbfb;
}

  
  .autocomplete-suggestion {
    padding: 10px;
    cursor: pointer;
  }
  
  .autocomplete-suggestion:hover {
    background-color: #f5f5f5;
  }
  .bold-uppercase {
    font-weight: bold;
    text-transform: uppercase;
    color: #000;
  }

  .label_tabel {
    color: #000;
    /* font-family: "Barlow,sans-serif"; */
    font-size: 13px;
    /* text-transform: uppercase; */
    font-weight: 500;
}
.label_resetp {
    text-align: center;
    margin: 30px;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
}


.label_addtocart_table {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    color: #059fe2!important;
}
.disable_qyt  {
        cursor: no-drop !important;
    }

    .checkout_delete:hover{
        background-color: #059fe2;
        border-color: #059fe2;
        color: #fff;
    }

        /* .search-container {
            display: flex;
            align-items: center;
        } */
      
      #search {
        padding: 5px;
        margin-right: 10px;
        border: 1px solid #ccc;
      }
      
      #search.error {
        border: 1px solid red !important;
      }

      .searchboxvalidation input {
        padding: 5px;
        margin-right: 10px;
        border: 1px solid #ccc;
      }
      
      .searchboxvalidation input.error {
        border: 1px solid red !important;
      }
/* end bulk order search input css */

  .vertical-carousel-wrapper {
    /* width: 200px; 
    height: 300px;  */
   
    width: 100%; 
    /* height: 400px;  */
    /* margin: auto; */
    position: relative;
  }
 .col-xl-6.col-lg-6.col-md-6.col-sm-12.slider-part > div > div > div > div > div {
    display: flex!important;
    flex-direction: row-reverse!important;
  }
  .col-xl-6.col-lg-6.col-md-6.col-sm-12.slider-part > div > div > div > div > div > div:nth-child(2) > div > ul{
    display: flex!important;
    flex-direction: column!important;
  }
  .col-xl-6.col-lg-6.col-md-6.col-sm-12.slider-part > div > div > div > div > div > div:nth-child(2) {
    width:fit-content;
    height: fit-content;
  }
  .col-xl-6.col-lg-6.col-md-6.col-sm-12.slider-part > div > div > div > div > div > div:nth-child(2) > div > ul{
    transform: translate3d(0px, 0px, 0px)!important;
    transition-duration: 0ms!important;
  }
 .col-xl-6.col-lg-6.col-md-6.col-sm-12.slider-part > div > div > div > div > div > div:nth-child(2) > div > ul > li {
        width: 47px!important;
  }

  .col-xl-6.col-lg-6.col-md-6.col-sm-12.slider-part > div > div > div > div > div.carousel-root > div.carousel.carousel-slider > ul
  {
   margin: 0px 0 !important;
  }
  .carousel .thumbs-wrapper {
    margin: 12px !important;
}

/* quick popup carousel all product img */
.col-xl-12.col-lg-12.col-md-12.col-sm-12.slider-part > div > div > div > div > div {
    display: flex!important;
    flex-direction: row-reverse!important;
  }
  .col-xl-12.col-lg-12.col-md-12.col-sm-12.slider-part > div > div > div > div > div > div:nth-child(2) > div > ul{
    display: flex!important;
    flex-direction: column!important;
  }
  .col-xl-12.col-lg-12.col-md-12.col-sm-12.slider-part > div > div > div > div > div > div:nth-child(2) {
    width:fit-content;
    height: fit-content;
  }
  .col-xl-12.col-lg-12.col-md-12.col-sm-12.slider-part > div > div > div > div > div > div:nth-child(2) > div > ul{
    transform: translate3d(0px, 0px, 0px)!important;
    transition-duration: 0ms!important;
  }
  .col-xl-12.col-lg-12.col-md-12.col-sm-12.slider-part > div > div > div > div > div > div:nth-child(2) > div > ul > li {
        width: 60px!important;
  }

  .col-xl-12.col-lg-12.col-md-12.col-sm-12.slider-part > div > div > div > div > div.carousel-root > div.carousel.carousel-slider > ul
  {
   margin: 0px 0 !important;
  }


/* end quick popup carousel all product img  */


  /* .vertical-carousel {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 300px; 
    height: 200px;
  } */
/* .slider-img-pro-detail{
    cursor: pointer;
    width: 60px;
    position: absolute;
    left: 10px;
    bottom: 231px;
} */
.slider-img-pro-detail {
    /* cursor: pointer; */
    /* width: 60px; */
    position: absolute;
    /* left: 14px;
    bottom: 196px; */
    left: -63px;
    bottom: 285px;
}
.thumbnail-image video {
    width: 43px;
    height: 44px;
    margin-right:6px;
    margin-bottom: 7px;
    background: #fff;
    display: flex;
    -o-object-fit: contain;
    object-fit: contain;
    align-items: center;
    border: 1px solid #e9e9e9;
    cursor: pointer;
}
  /* .dropdown-item:hover, .dropdown-item:focus{
    background-color: gray!important;;
  } */
  .ChagePassFrom {
    width: 50%;
    margin: auto;
}

/* block detail page css */
.blog-detail-sec .post-block .post-content {
    padding: 20px 15px 0;
}
.blog-detail-sec .post-block .post-content .post-heading {
    font-size: 36px;
    color: #333;
    font-weight: 500;
}
.post-meta1 {
    display: flex;
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    padding: 8px 0;
    margin: 25px 0;
    align-items: center;
}

.blog-detail-sec .post-block .post-content .entry-content p {
    font-size: 18px;
    line-height: 24px;
    color: #565656;
    margin-bottom: 25px;
}


.blog-detail-sec .post-block .social-share ul li a {
    padding: 0 6px;
    color: #aaa;
    font-size: 15px;
}

.form-select  product-select{
    border-radius: none;
}




.input-group>.form-control, .input-group>.form-floating, .input-group>.form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
}
.form-select  .product-select{
   background-image: none;
  
}

/* end block detail page css */


/* arpit 29 jan css */
.checkout_delete
{
    font-weight: 200;
    background: #fff;
    font-size: 14px;
    border: 2px solid #f1f1f1;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #059fe2;
    position: absolute;
    top: 19px;
    right: 12px;
}
.checkout_delete:hover{
    background-color: #059fe2;
    border-color: #059fe2;
    color: #fff;
}
.delete_hover a:hover{
    background-color: #059fe2;
    border-color: #059fe2;
    color: #fff;
}
#root > div > div > section > div > div.row > div > div.col-xl-8.col-lg-8.col-md-12.col-sm-12.post-detail > div > div.col-12.pb-2.d-flex.justify-content-between.bg-white.collsaped > div > div > div > div > div > div > div.form-group.col-12.mt-3 > div > div > div.col-xl-3.col-lg-3.col-md-12.p-xl-0.p-lg-0 > div > div > p > a:hover i {
    background-color: #059fe2;
    border-color: #059fe2;
    color: #fff;
}
.editAddressHover p:hover{
    color: #000!important;
}
.mng{
    padding-right: 20px!important;
    padding-left: 20px!important;
}

/* use for filter section full remove border around like category, price,brand */
#product-listing-sec > div > div > div.col-xl-3.col-lg-3.col-md-12.col-sm-12 > div{
    border: none!important;
}
#secondary > div.card{
    border: none!important;
}
/* end use for filter section full remove border around like category, price,brand */

/* arpit today 1 feb css changes */
#root > div > div > section.product-detail-sec.inner-page.inner-page-bg > div > div > div.col-xl-6.col-lg-6.col-md-6.col-sm-12.slider-part > div > div > div > div > div.carousel-root > div:nth-child(2) > div > ul li:hover{
    border-color: #059fe2!important;
}

#root > div > div > section.product-detail-sec.inner-page.inner-page-bg > div > div > div.col-xl-6.col-lg-6.col-md-6.col-sm-12.slider-part > div > div > div > div > div.carousel-root > div:nth-child(2) > div > ul > li.thumb.selected {
    border-color: #059fe2!important;
}

#root > div > div > section.product-detail-sec.inner-page.inner-page-bg > div > div > div.col-xl-6.col-lg-6.col-md-6.col-sm-12.slider-part > div > div > div > div > div.carousel-root > div:nth-child(2){
    margin-top: -14px!important;
}

.carousel .thumb.selected, .carousel .thumb:hover>img {
    border: none!important;
}

.carousel .thumb{
    border: 1px solid #fff!important;
}
#root > div > div > section.product-detail-sec.inner-page.inner-page-bg > div > div > div.col-xl-6.col-lg-6.col-md-6.col-sm-12.slider-part > div > div > div > div > div.carousel-root > div.carousel.carousel-slider{
    border: 1px solid #e9e9e9!important;
    height: 547px!important;
    background-color: #fff;
}

#root > div > div > section.product-detail-sec.inner-page.inner-page-bg > div > div > div.col-xl-6.col-lg-6.col-md-6.col-sm-12.slider-part > div > div > div > div > div.carousel-root > div:nth-child(2) {
    margin-left: -100px!important;
}

.slider_img_border {
background: #fff;
    display: flex;
    -o-object-fit: contain;
    object-fit: contain;
    align-items: center;
    border: 1px solid #e9e9e9;
    cursor: pointer;
}
.vertical-carousel-wrapper{
    background: #fff;
    display: flex;
    -o-object-fit: contain;
    object-fit: contain;
    align-items: center;
    border: 1px solid #e9e9e9;
}
.videofix {
    height: 222px;
    position: relative;
    margin-top: -43px;
    margin-bottom: 50px;
}
.techDocTab {
    color: #565656;
    font-size: 21px;
    margin-bottom: 22px;
}

#tech_document .tech-doc-block a{
border: 2px solid #e5e5e5;
    display: block;
    padding: 24px;
    font-size: 21px;
    position: relative;
    color: #059fe2;
    font-weight: 500;
}
#tech_document .tech-doc-block i.fa.fa-arrow-down{
    font-size: 13px;
    position: absolute;
    right: 13px;
    top: 19px;
    border: 1px solid #dfdfdf;
    color: #b0b0b0;
    padding: 10px;
    border-radius: 50%;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.faqTitle{
    font-size: 30px;
    color: #000;
    font-weight: 600;
    margin-bottom: 15px;
}
.faq_cart_title{
    display: inline-block;
    margin: 0;
    font-size: 24px;
    font-weight: 500;
    color: #333;
    cursor: pointer;
}
.accordion-button span{
    color: #333;
    /* background-color: #fff; */
    display: inline-block;
    margin: 0;
    font-size: 24px;
    font-weight: 500;
}
.accordion-button:not(.collapsed){
    background-color: #fff;
}
.accordion-button{
    border: 2px solid #eee;
    padding: 20px 20px 20px;
    border-radius: 4px;
    margin-bottom: 20px;
    box-shadow: 0 3px 6px -4px rgba(0,0,0,.16);
}
.accordion-item{
    border: none!important;
}

.product-tab-sec .nav-tabs .nav-item {
    padding: 12px 20px;
    margin-bottom: 0;
    position: relative;
}
/* End arpit today 1 feb css changes */

/* 2feb24 */

/* quck modal css for slider 360 */
#quickview2408___BV_modal_body_ > div > div > div.col-xl-6.col-lg-6.col-md-6.col-sm-12.slider-part > div > div > div.card-carousel.gallery-active > div.card-img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: calc(100% - 32px);
}
#quickViewModel > div > div > div.modal-body > div > div > div:nth-child(1) > div > div > div > div > div > div > div.carousel-root > div:nth-child(2) {
    margin-left:12px!important;
}
#quickViewModel > div > div > div.modal-body > div > div > div:nth-child(1) > div > div > div > div > div > div > div > div.carousel.carousel-slider {
    border: 1px solid #e9e9e9!important;
    height: 520px!important;
    background-color: #fff;
}

#quickViewModel > div > div > div.modal-body > div > div > div:nth-child(1) > div > div > div > div > div > div > div > div.carousel.carousel-slider > div > ul > li.slide.selected.previous{
    border-right: 1px solid #e9e9e9!important;
    border-left: 1px solid #e9e9e9!important;
    height: 580px!important;
    background-color: #fff;
}
.carousel .slide iframe{
    /* width: calc(100% - 0px)!important; */
    width: calc(140% - 0px)!important;
    margin: -38px !important;
}
#quickViewModel > div > div > div > div > div > div:nth-child(1) > div > div > div > div > div > div > div > div:nth-child(2) > div > ul li img{
    border: 1px solid #e9e9e9!important;
}
#quickViewModel > div > div > div > div > div > div:nth-child(1) > div > div > div > div > div > div > div > div:nth-child(2) > div > ul li:hover{
    border-color: #059fe2!important;
}
#quickViewModel > div > div > div > div > div > div:nth-child(1) > div > div > div > div > div > div > div > div:nth-child(2) > div > ul > li.thumb.selected{
    border-color: #059fe2!important;
}
/* slider or main img level */
#quickViewModel > div > div > div.modal-body > div > div > div:nth-child(1) > div > div > div > div > div > div > div > div:nth-child(2){
    margin-top: -14px;
}
#quickViewModel > div > div > div > div > div > div:nth-child(1) > div > div > div > div > div > div > div > div:nth-child(2) > div > ul > li img {
    width: 70px!important;
    /* height: 65px!important; */
    height: 56px!important;
}
.action_border{
    display: flex;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    margin: 10px 0;
}
.modal-body
{
    /* padding: 3rem!important; */
    margin: 9px!important;
}
.modal-title{
    font-size: 1.2em!important;
    font-weight: 600!important;
    text-transform: uppercase;
    margin-top: 10px!important;
}
.btn-close .remove-button {
    position: absolute;
    right: 0;
    background: #059fe2;
    top: 0;
    height: 28px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
}
#quickViewModel > div > div > div > button{
    position: absolute;
    /* right: 0; */
    right: -9px;
    background: #059fe2;
    /* top: 0; */
    top: -9px;
    height: 28px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
}
#addToCartP > div > div > div > button{
    position: absolute;
    /* right: 0; */
    right: 8px;
    background: #059fe2;
    /* top: 0; */
    top: 8px;
    height: 23px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
}
.slider-img-pro-detail_quick {
    position: absolute;
    /* left: 14px;
    bottom: 196px; */
    left: 27px;
    bottom: 149px;
    /* position: relative; */
}
.thumbnail-image_quick video {
    width: 54px;
    height: 54px;
    margin-right: 6px;
    background: #fff;
    display: flex;
    object-fit: contain;
    align-items: center;
    border: 1px solid #e9e9e9;
    cursor: pointer;
}

.thumbnail-image_quick
{
    float: left;
    margin: 0;
}
/* end quck modal css for slider 360 */
/* #accordionExample0 > div > .accordion-item:hover {
    border: 2px solid #059fe2;
} */
/* End 2feb24 */
.dashed{
    border-top: 1px dashed #bbb!important;
    background-color: #2f3235;
   
}
.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #F2F2F2!important;
}

#my-table1 > tbody > tr:nth-child(1) > td:nth-child(5) > i > a > i:hover
{
    color: dark blue;
}
/* .form-control
{
font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
} */
/* end arpit 29 jan css */

/* end arpit css */
@media only screen and (max-width: 1024px) {
    .deleteimg {
       margin-right: 30px;
      
    }
}

@media only screen and (max-width: 992px) {
   
    .center-price {
        padding-top: 80px;
        width: auto;
        
    }
    .deleteimg {
        margin-right: 95px;
       
     }
}

@media only screen and (max-width: 769px) {
    .center-price {
        padding-top: 80px;
        width: auto;
        
    }

  
}


/* added rfl akash */
.rfq-item1 {
    height: 50px;
    background-color: rgba(0, 0, 0, .06);
    border-bottom: 1.5px solid #ccc;
    border-radius: 4px;
  }
  
.rfq-id,
.order-id,
.separator,
.item-count,
.placed-on {
  color: black;
  font-weight: 500;
}
.rfq-id {
    font-weight: 400; 
  }

.separator {
  margin: 0 10px;
}

@media screen and (max-width: 600px) {
  .gap-adjust {
    font-size: 14px; 
  }
  .separator {
    margin: 0 5px; 
  }
}

/* for password error message */
.error-border-password {
    border-color: #dd3b4b;
  }
  .input-container-icon {
    position: relative;
    display: flex;
  }
  .warning-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #dd3b4b;
    font-size: 1.2em;
  }
  .old-password-password {
    border-radius: 0; 
    height: 45px;
  }
  .error-message-password {
    background: #d4edda; 
    color: #28a745; 
    padding: 12px; 
    margin-bottom: 2px;
    border: 1px solid #c3e6cb; 

    border-radius: 3px;

  }
  .myItems-changepassword {
    font-weight: 600;
  }
  
/* for profile boder separation */
  .profile-half {
    width: 40%;
  }

/* add adress icon css */
  .input-with-icon-address {
    position: relative;
  }
  
  .warning-icon-address{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px; 
    color: #ff0000; 

  }
  .error-message-password-address {
    background: #f8d7da; 
    color: #a51212; 
    padding: 12px; 
    margin-bottom: 2px;
    border: 1px solid #f8d7da; 
    text-align: center;  

    border-radius: 3px;

  }
  
  