/* 29-1-2024 ajay Start */
html{
    width: 100vw;
    overflow-x: hidden;
}
    /* 29-1-2024 ajay end */
body
{
    /* overflow: visible!important; */
    overflow-y: visible!important;
}
.navbar {
  --bs-navbar-toggler-focus-width: 0rem !important; 
}

a{
   text-decoration: none;
}

.hideinmobile{
   display: block !important;
   z-index: 2;
}
.showinmobile{
   display: none !important;
}

.searchwidth{
width: 95%;
}

.searchbtnwidth{
   width: 5%;
}


@media only screen and (max-width:1900px) {
    /* 30-1-2024 start  */
    .hideinmobile{
        display: block !important;
        z-index: 2;
    }
    
    .height2{
        height: 100%;
    }
    /* 30-1-2024 end  */

   .desginforheight{
    height: 508px;
    align-items: normal;
   }


   .finalwidth{
    width: 92%;
   }
   .showinmobile{
       display: none !important;
   }

   /* 29-1-2024 ajay */
.newcustomhiegiht{
    height: 500px;
}
.custozsearch {
    width: 668px !important;
    /* width: 648px !important; */
    /* width: 38.9% !important; */
}
header .main-header .logo img {
    max-width: 145px !important;
    padding: 10px -5px;
    position: relative;
    left: -10px;
}

#desktop-menu{
    padding: 5px 0px;
}

.customnav{
    width: 89% !important;
    display: flex !important;
    justify-content: center !important;
}
/* 29-1-2024 ajay */
}
@media only screen and (max-width:1400px) {
   .hideinmobile{
       display: block !important;
       z-index: 2;
   }

   .showinmobile{
       display: none !important;
   }

}
/* 29-1-2024 ajay Start */

@media only screen and (max-width:1350px) {
    .custozsearch {
        /* width: 598px !important; */
        width: 647px !important;
        /* width: 38.9% !important; */
    }
    }

        /* 29-1-2024 ajay Start */
@media only screen and (max-width:1250px) {
    .custozsearch {
        width: 598px !important;
        /* width: 38.9% !important; */
    }
    .customnav{
        width: 100% !important;
        display: flex !important;
        justify-content: center !important;
    }
    }
        /* 29-1-2024 ajay end */

    @media only screen and (max-width:1080px) {
        .custozsearch {
            width: 598px !important;
            /* width: 38.9% !important; */
        }
        }
    /* 29-1-2024 ajay end */
    @media only screen and (max-width:990px) {
        .hideinmobile{
            display: none !important;
        z-index: -1;
     
        }
        .extrapadding2{
            padding-top: 4px;
        }
     /* 30-1-2024 start  */
     .desginforheight{
         height: auto;
         align-items: normal;
        }
        /* 30-1-2024 end  */
        .customnav{
         width: 100% !important;
         display: none !important;
         justify-content: center !important;
     }
        .showinmobile{
            display: flex !important;
        }
        .searchwidth{
            width: 95%;
            }
            
            .searchbtnwidth{
                width: 5%;
            }
     }
@media only screen and (max-width:700px) {
   .hideinmobile{
       display: none !important;
   z-index: 2;

   }
   .finalwidth{
    width: 100%;
   }
   .searchwidth{
       width: 92%;
       }
       
       .searchbtnwidth{
           width: 8%;
       }

   .showinmobile{
       display: flex !important;

   }
}
@media only screen and (max-width:675px) {
   .hideinmobile{
       display: none !important;
   }
   .showinmobile{
       display: flex !important;
   }
}



a {
    text-decoration: none;
}

.app{
    width: 100vw;
    overflow: hidden;
}


/* footer css start */

/* #root{
    width: 1000vw;
    overflow: hidden;
} */

.section_padding {
    padding: 4rem;
}

/* .smpadding{
    padding: 0px;
} */

.title2{
    font-size: 17px;
    font-weight: 700;
    list-style: 24px;
}
.title3{
    font-size: 24px;
    color: #059fe2;
    line-height: 1.2;
    font-weight: 400;
    margin-bottom: 25px;
}

.title4{
    font-size: 16px;
    font-weight: 700;
    color: #333;
    line-height: 24px;
    margin-bottom: 20px;
    text-transform: uppercase;
}
.link2{
    color: #059fe2;
    font-size: 14px;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 7px;
}

.desc2{
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    color: #565656 !important;
    margin: 0px !important;
}


.topfooter {
    display: flex;
    justify-content: space-between;
    background-color: #f7f7fa;
    border-top: 1px solid #e0e0e0;
    padding-top: 22px;
    padding-bottom: 22px;
    flex-wrap: wrap;
    padding-left: 20px;
    padding-right: 20px;
}

.sb_topfooter h4 {
    font-size: 21px;
    font-weight: 650;
    padding-bottom: 7px;
}

.sb_topfooter h5 {
    font-size: 14px;
    color: #9ba0b1;
    font-weight: 700;
    padding-bottom: 10px;
}

.sb_topfooter p {
    font-size: 14px;
    color: grey;
    font-weight: 400;
}

/* .shop{
    padding-top: 200px;
} */



.footer {
    background-color: white;
}

.sb_footer {
    display: flex;
    flex-direction: column;
}

.sb_footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    margin-bottom: 0.7rem;
}

.sb_footer-links_div {
    /* width: 150px; */
    margin: 1rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color: black;
    font-weight: bold;
}

a {
    color: gray;
    text-decoration: none;
}

.socialmedia {
    display: flex;
    flex-direction: row;
}

.socialmedia img {
    width: 80%;

}

.sb_footer-links_div h4 {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0.9rem;
}

.sb_footer-links_div p {
    font-size: 14px;
    margin: 0.4rem 0;
    cursor: pointer;
    line-height: 1.1em;
    color: #4e5361;
    font-weight: 400;
}

.sb_footer-below {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.2rem;
}

.sb_footer-below-links {
    display: flex;
    padding-left: 200px;
    justify-content: space-between;
    flex-direction: row;
    padding-bottom: 50px;
    gap: 20px;
}

.sb_footer-below-links p {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    margin-left: 2rem;
    color: black;
    font-weight: 600;
}

hr {
    color: white !important;
    width: 100%;
}

.sb_footer-copyright p {
    padding-left: 100px;
    font-size: 13px;
    line-height: 15px;
    color: black;
    text-align: center;
    font-weight: bold;
}

.bottom-footer {
    padding: 13px 0;
    background-color: #f7f7fa;
    border-top: 1px solid #e0e0e0;
}

.main-footer .media-icon i.fa {
    font-size: 28px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin-left: auto;
}


.bottom-footer .other-option a {
    padding-right: 13px;
    color: grey;
    font-size: 15px;
    font-weight: 500;
}



















@media screen and (max-width: 850px) {
    .sb_footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 768px) {
    .sb_footer-links_div p {
        margin-left: 50px;
    }

    .sb_footer-links_div h4 {
        margin-left: 50px;
    }
}

@media screen and (max-width: 550px) {
    .sb_footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }
    .smpadding{
        padding: 0px 30px !important;
    }

    .sb_footer-links_div {
        margin: 1rem 0px;
    }

    .sb_footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }

    .sb_footer-below {
        flex-direction: column;
        justify-content: left;
    }

    .sb_footer-below-links {
        flex-direction: column;
    }

    .sb_footer-below-links p {
        margin-left: 0rem;
        margin-top: 1rem;
    }
    /* 29-1-2024 ajay Start */
    .paddinginmobile{
        padding: 8px 9px;
    }
/* 29-1-2024 ajay end */
}

@media screen and (max-width: 400px) {
    .sb_footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
    .smpadding{
        padding: 0px 30px !important;
    }
    /* 29-1-2024 ajay Start */

    .paddinginmobile{
        padding: 8px 9px;
    }
    /* 29-1-2024 ajay end */

}




/* header css */






/* brand page css */
.boxhover :hover {
    transition: all .2s ease-in-out;
    cursor: pointer;
    transform: scale(1.1);
    z-index: 1;
    box-shadow: 0px 0px 12px gray;
    border: 2px solid #ddd;
}

.boxhover231{
    border: 1px solid #efefef !important;
    border-radius: 0px !important;
}

.boxhover231 img{
    aspect-ratio: 2.5/1;
}
.boxhover231:hover{
    transition: all .2s ease-in-out;
    cursor: pointer;
    transform: scale(1);
    background-color: white;
    z-index: 1;
    box-shadow: 0px 0px 12px gray;
    border: 0.3px solid #ddd;
}

/* Best-sellers page css */
.box1 {
    padding: 20px;
    background: #fff;
    border: 1px solid #ececec;
    margin-bottom: 5px;
}

.box2 {
    padding: 20px;
    background: #fff;
    border: 1px solid #ececec;
    margin-bottom: 5px;
}

.box3 {
    padding: 20px;
    background: #fff;
    border: 1px solid #ececec;
    margin-bottom: 5px;
}

.box4 {
    padding: 20px;
    background: #fff;
    border: 1px solid #ececec;
    margin-bottom: 5px;
}

.collapse-block-title {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    line-height: 1.2;
    padding-bottom: 15px;
    cursor: pointer;
    position: relative;
}

.topinput {
    color: #999;
    font-size: 16px;

}

.boxsmalltext {
    font-size: 16px;
    color: #5e5e5e;
    font-weight: 300;
    padding: 7px 0;
    display: inline-block;
    position: relative;

}

.boxsmalltext a {
    text-decoration: none;
    transition: all .2s ease-in-out;

}

.searchbox {
    background: #fff;
    height: 50px;
    border: 1px solid #ececec;
    position: relative;
    padding: 0 20px;
    margin-bottom: 10px;
}

.searchbox h4 {
    text-align: center;
    padding-top: 15px;
    color: #999;
    font-size: 16px;
}

.products {
    background: none;
    height: 50px;
    /* border: 1px solid #ececec; */
    /* border-bottom: 1px solid rgba(128, 128, 128, 0.441); */
}

.products h4 {
    font-size: 25px;
    color: #060407;
    font-weight: 300;
    display: inline-block;
    padding-right: 5px;
}

.products span {
    font-size: 12px;
    color: #565656;
    display: inline-block;
    line-height: 19px;
}

.lastbutton {

    /* padding: 11px 11px 11px 29px; */
    padding-bottom: 8px;
    border-color: #059fe2;
    /* position: relative; */
    margin-top: 30px;
}

.one {
    font-size: 15px;
    line-height: 20px;
    text-transform: uppercase;
    background: #fff;
    color: black;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 2px solid #059fe2;
    /* display: inline-block; */
    margin-right: 5px;
    display: flex;
    margin: 0px;
    padding: 10px 10px;
    display: flex !important;
    gap: 2px;

}

.two:hover {
    background: black;
    color: #fff;
    border: 0px;
}

.one:hover {
    background: #059fe2;
    color: #fff;
    border: 0px;
}
.title345{
    font-size: 14px;
    font-weight: 700;   
    color: #333;
    line-height: 26px;
   
}

.title3451-2{
    padding-left: 24px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 32px;
}
.title098-2{
    padding-left: 24px;
   font-size: 18px;
   font-weight: 600;
   text-transform: uppercase;
   color: #059fe2;
   line-height: 32px;
}

.two {
    font-size: 15px;
    line-height: 20px;
    text-transform: uppercase;
    color: #464646;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 2px solid #059fe2;
    /* display: inline-block; */



}

.afterdot::after{
    content: '.';
    padding: 0px 5px;
    border-radius: 50%;
    background-color: #299e22;
    color: #299e22;
    position: absolute;
    right: 0px;
    bottom: -8px;
}

.desc65{
    font-weight: 400;
    font-size: 14px;
    color: #565656;
    padding: 0px 37px;
}
.dropdown-toggle::after{
    display: none !important;
}

.title65{
    font-size: 16px;
    font-weight: 500;
}
.collsaped{
    height: auto;
    transition: all 0.5s ;
}

.deletebtn2{
    width: fit-content;
    background: white;
    padding: 10px;
    border-radius: 50%;
        position: absolute;
    right: 10px;
    top: 14px;
    font-size: 11px;
    color: #059fe2;
}

.customrow {
    --bs-gutter-x: 0px !important;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}

.title451{
    font-size: 1.2em;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 10px;
}

.popaddresswidth{
    max-width: 798px !important;
}

.combo1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.title098{
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    color: #059fe2;
    line-height: 32px;
}

.title3451{
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 32px;
}

/* .title345{
    font-size: 14px;
    font-weight: 700;
    color: #333;
    line-height: 26px;
    padding: 0;
} */

.title345{
    font-size: 14px;
    font-weight: 700;  
    color: #333;
    line-height: 26px;
   
}

.uncollsaped{
    height: 0px;
    display: none !important;
    transition: all 0.5s ;

}

.sendotpbtn{
    font-size: 15px;
    font-weight: 600;
    color:#059fe2;
    padding: 12px 18px;
    background-color: white;
}
.contunubtn{

    background-color: #059fe2;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    padding-left: 20px;
    padding-right: 20px;
    letter-spacing: 1px;
    transition: all .4s ease-in-out;
}
.contunubtn:hover{

    background-color: #000;
    color: white;
    transition: all .4s ease-in-out;
}
.sendotpbtn:hover{
    font-size: 15px;
    font-weight: 600;
    background-color:#059fe2;
    color: white;
}
.sendotpbtn-address-page:hover{
    font-weight: 600;
    background-color:#000000;
    color: white;
    margin-bottom: 40px;

    /* padding: 12px 18px; */
}
.sendotpbtn-address-page {
    font-weight: 600;

    border: 2px solid #059fe2;
    border-radius: 0;
    width: 100%;
    color: #fff;
    background: #059fe2;
    margin-bottom: 40px;
  }
  

.tilte54{
    /* font-weight: 500; */
    font-size: 16px;
    color: #000;
    line-height: 22px;
}
.tilte541{
    /* font-weight: 500; */
    font-size: 16px;
    color: #000;
   
    line-height: 22px;
}

.tilte541 ~ input{
    color: #767272;
}

.title76{
    font-size: 18px;
    font-weight: 600;
    color: #000;
}


.desc44{
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #4c4c4c;
    padding: 0px 34px !important;
}

.title90{
    cursor: pointer;
    font-size: 18px;
    color: #fff;
    font-weight: 500;
}
.title5{
    font-weight: 600;
    color: #000;
    font-size: 18px;
}
.sidebar2 {
    border: 1px solid #e3e3e3;
    font-size: 14px;
    color: #6d6d6d;
    line-height: 18px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}



.sidebar {

    width: 60%;
    border: 1px solid #e3e3e3;
    /* background: #fff; */
    font-size: 14px;
    color: #6d6d6d;
    line-height: 18px;
    padding: 5px 20px 5px 10px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    margin: 0 5px;
}

.rightone {
    box-sizing: border-box;
    align-items: center;
    background: #fff;
    text-align: center;
    border: 2px solid #e6e6e6;
    padding: 10px 0;
    padding-top: 50px;
    margin-bottom: 15px;
    position: relative;
}

.rightone:hover {
    border: 2px solid #059fe2;
}

.imageheart {
    font-size: 30px;
    padding-right: 20px;
    color: rgba(128, 128, 128, 0.548);
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    justify-content: right;
    flex-direction: column;
    opacity: 0;
    transition: 0.6s;

}


.imageheart:hover {
    opacity: 1;
}

.imageheart {
    transform: translateY(25px);
    transition: transform 0.6s;
}

.rightone:hover>.imageheart {
    opacity: 1;
}

.eyeone {
    color: #059fe2;
    position: absolute;
    display: flex;
    padding-left: 2px;
    /* left:5px; */
    bottom: 32px;
    content: "\f06e";
    font-family: 'FontAwesome';
}

.one:hover .lastbutton .eyeone {
    color: #fff;
}

.lastbutton {
    display: flex !important;

    justify-content: space-around;
    gap: 2px;
}

.minus {
    font-size: 10px;
    opacity: 0;
    transition: 0.6s;
}

.minus:hover {
    opacity: 1;
}

.minus {
    transform: left translateY(25px);
    transition: transform 0.6s;

}

.minus {
    transform: translateY(10px);


}

.boxsmalltext a:hover>.minus {
    opacity: 1;
}

.boxsmalltext a:hover {
    color: blue;
}

.sortby {

    display: flex;
    font-Size: 14px;
    font-Weight: 300;
    color: #333;
    padding-Top: 15px;
}


@media screen and (max-width: 600px) {

    .sidebar {

        width: 70%;
    }

}

@media screen and (max-width: 500px) {

    .sidebar {
        width: auto;
    }

    .sortby {
        width: auto;
        font-Size: 11px;

    }

}

.bulkorder-text {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.longbutton {

    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #fff;
    border: 1px dashed #b2b2b2;
    color: #858585;
    font-weight: 400;
    font-size: 16px;

}



/* Home page css */


.Topbackground {
    height: 400px;
}


.white-bg {
    background: #fff;
    padding: 30px 20px 30px 20px;
    border-radius: 7px;
    width: 100%;


}

.item {
    border: 1px solid gray;
    height: 280px;
    align-items: center;
}

.owlimg1 {
    padding-top: 30px;
    left: 20px;
    right: 0;
    margin: auto;
    max-width: 100%;

}


.textimage {
    font-size: 16px;
    display: flex;
    justify-content: center;
    font-weight: 600;
}

.text2image {
    font-size: 16px;
    display: flex;
    justify-content: center;
    font-weight: 600;
}

.text3image {
    font-size: 16px;
    display: flex;
    justify-content: center;
    font-weight: 600;
}

/* Top .owl-carousel */

#root>div>div>section:nth-child(1)>div>div>div>div>div.owl-nav>button.owl-prev span {
    position: absolute;
    top: 35%;
    left: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(1)>div>div>div>div>div.owl-nav>button.owl-next span {
    position: absolute;
    top: 35%;
    right: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(1)>div>div>div>div>div.owl-nav>button.owl-prev span:hover {
    background: #059fe2;
}

#root>div>div>section:nth-child(1)>div>div>div>div>div.owl-nav>button.owl-next span:hover {
    background: #059fe2;
}


/* Best Selling Products */

#root>div>div>section:nth-child(3)>div>div>div.owl-nav>button.owl-prev span {
    position: absolute;
    top: 35%;
    left: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(3)>div>div>div.owl-nav>button.owl-next span {
    position: absolute;
    top: 35%;
    right: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(3)>div>div>div.owl-nav>button.owl-prev span:hover {
    background: #059fe2;
}

#root>div>div>section:nth-child(3)>div>div>div.owl-nav>button.owl-next span:hover {
    background: #059fe2;
}

/* .......Shoes Safety....... */

#root>div>div>section:nth-child(5)>div>div>div.owl-nav>button.owl-prev span {
    position: absolute;
    top: 35%;
    left: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(5)>div>div>div.owl-nav>button.owl-next span {
    position: absolute;
    top: 35%;
    right: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(5)>div>div>div.owl-nav>button.owl-prev span:hover {
    background: #059fe2;
}

#root>div>div>section:nth-child(5)>div>div>div.owl-nav>button.owl-next span:hover {
    background: #059fe2;
}

/* .........Eye Protection......... */

#root>div>div>section:nth-child(6)>div>div>div.owl-nav>button.owl-prev span {
    position: absolute;
    top: 35%;
    left: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(6)>div>div>div.owl-nav>button.owl-next span {
    position: absolute;
    top: 35%;
    right: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(6)>div>div>div.owl-nav>button.owl-prev span:hover {
    background: #059fe2;
}

#root>div>div>section:nth-child(6)>div>div>div.owl-nav>button.owl-next span:hover {
    background: #059fe2;
}

/* ..........Hand Protection.......... */

#root>div>div>section:nth-child(7)>div>div>div.owl-nav>button.owl-prev span {
    position: absolute;
    top: 35%;
    left: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(7)>div>div>div.owl-nav>button.owl-next span {
    position: absolute;
    top: 35%;
    right: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(7)>div>div>div.owl-nav>button.owl-prev span:hover {
    background: #059fe2;
}

#root>div>div>section:nth-child(7)>div>div>div.owl-nav>button.owl-next span:hover {
    background: #059fe2;
}


/* ........Head Protection......... */


#root>div>div>section:nth-child(8)>div>div>div.owl-nav>button.owl-prev span {
    position: absolute;
    top: 35%;
    left: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(8)>div>div>div.owl-nav>button.owl-next span {
    position: absolute;
    top: 35%;
    right: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(8)>div>div>div.owl-nav>button.owl-prev span:hover {
    background: #059fe2;
}

#root>div>div>section:nth-child(8)>div>div>div.owl-nav>button.owl-next span:hover {
    background: #059fe2;
}

/* Our Top Brands */
#root>div>div>section:nth-child(9)>div>div>div.owl-nav>button.owl-prev span {
    position: absolute;
    top: 1%;
    left: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}


/* .....BRAND PAGE CSS...... */
.brand-section {
    font-size: 26px;
    line-height: 1.2;
    padding: 15px 0px 15px 15px;
    border-bottom: 1px solid #efefef;
    background: #fff;
    border-radius: 7px 7px 0 0;
    color: #059fe2;



}

#root>div>div>section:nth-child(9)>div>div>div.owl-nav>button.owl-prev span:hover {
    background: #059fe2;
}

#root>div>div>section:nth-child(9)>div>div>div.owl-nav>button.owl-next span {
    position: absolute;
    top: 1%;
    right: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(9)>div>div>div.owl-nav>button.owl-next span:hover {
    background: #059fe2;
}


/* Respiratory Protection */
#root>div>div>section:nth-child(10)>div>div>div.owl-nav>button.owl-prev span {
    position: absolute;
    top: 35%;
    left: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(10)>div>div>div.owl-nav>button.owl-prev span:hover {
    background: #059fe2;
}

#root>div>div>section:nth-child(10)>div>div>div.owl-nav>button.owl-next span {
    position: absolute;
    top: 35%;
    right: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(10)>div>div>div.owl-nav>button.owl-next span:hover {
    background: #059fe2;
}


/* Hearing Protection */

#root>div>div>section:nth-child(11)>div>div>div.owl-nav>button.owl-prev span {
    position: absolute;
    top: 35%;
    left: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(11)>div>div>div.owl-nav>button.owl-prev span:hover {
    background: #059fe2;
}

#root>div>div>section:nth-child(11)>div>div>div.owl-nav>button.owl-next span {
    position: absolute;
    top: 35%;
    right: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(11)>div>div>div.owl-nav>button.owl-next span:hover {
    background: #059fe2;
}

/* ......Safety Clothing....... */
#root>div>div>section:nth-child(12)>div>div>div.owl-nav>button.owl-prev span {
    position: absolute;
    top: 35%;
    left: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(12)>div>div>div.owl-nav>button.owl-prev span:hover {
    background: #059fe2;
}

#root>div>div>section:nth-child(12)>div>div>div.owl-nav>button.owl-next span {
    position: absolute;
    top: 35%;
    right: 0px;
    color: white;
    font-size: 65px;
    background: rgba(0, 0, 0, .2);
    width: 3%;
    padding-bottom: 10px;
}

#root>div>div>section:nth-child(12)>div>div>div.owl-nav>button.owl-next span:hover {
    background: #059fe2;
}

.heartimg {
    font-size: 28px;
    padding-right: 10px;
    color: rgba(128, 128, 128, 0.548);
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    justify-content: right;
    flex-direction: column;
    opacity: 0;
    transition: 0.6s;
}



.heartimg:hover {
    opacity: 1;
}

.heartimg {
    transform: translateY(25px);
    transition: transform 0.6s;
}

.item:hover>.heartimg {
    opacity: 1;
}

.trustpay h1 {
    text-align: center;
}

.trustpay p {
    text-align: center;
}

.great {
    align-items: center;
}

.twoimagecol {
    margin-bottom: 30px;
}

.BestSelling {
    color: #000;
    font-size: 25px;
    text-align: left;
    font-weight: 600;
    line-height: 1.2;
    padding-bottom: 25px;
    text-transform: capitalize;
}

.allbutton {
    position: absolute;
    right: 35px;
    top: px;
    background: #059fe2;
    color: #fff;
    /* padding: 8px 13px; */
    line-height: 1.2em;
    font-size: 13px;
    font-weight: 600;
}

.allbutton span a:hover {
    color: #000;
}

#root>div>div>section:nth-child(5)>div>h1>span>a>button:hover {
    background: black;
}

#root>div>div>section:nth-child(6)>div>h1>span>a>button:hover {
    background: black;
}

#root>div>div>section:nth-child(7)>div>h1>span>a>button:hover {
    background: #000;
}

#root>div>div>section:nth-child(8)>div>h1>span>a>button:hover {
    background: #000;
}




.owl-carousel .owl-item img {
    max-width: 100% !important;
}


.categoryList h4 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
    background: #059fe2;
    padding: 17px 22px;
}

.categoryList a {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 9px 22px;
    font-size: 15px;
    font-weight: 600;
    color: #000;
    border-bottom: 1px solid #ececec;
    direction: ltr;
}

.category {
    font-size: 16px;
    color: #3d3d3d;
    background: #efefef;
    display: inline-block;
    width: 100%;
    padding: 18px 22px;
    text-transform: capitalize;
}

.cat {
    display: none;
    position: absolute;
    right: -267px;
    top: 0px;
    background: white;
    width: 100%;
    border: 1px solid gray;
    height: 360px;
    overflow: auto;

}

.cat ul li a {
    color: #000;
    font-weight: 700;
    font-size: 14px;
    padding: 12px 20px 3px;
    box-sizing: border-box;
    border: none;
}

.cat ul li p {
    font-size: 14px;
    color: #545454;
    font-weight: 400;
    padding-left: 20px;
    padding-top: 7px;
    line-height: 10px;
    box-sizing: border-box;
    border: none;
}

.cat ul li a:hover {
    color: #059fe2;
}

.catimage:hover~div {
    display: block;
}

.cat:hover {
    display: block;
}

.cat2 {
    display: none;
    position: absolute;
    right: -528px;
    top: 0px;
    background: #eee;
    width: 100%;
    height: 360px;
    overflow: scroll;

}

.cat2 ul li a:hover {
    color: #059fe2;
}

.cat2 ul li p {
    font-size: 14px;
    color: #545454;
    font-weight: 400;
    padding-left: 20px;
    padding-top: 7px;
    line-height: 10px;
    box-sizing: border-box;
    border: none;
}

.cat:hover~div {
    display: block;
}

.cat2:hover {
    display: block;
}

.cat2:hover>.cat {
    display: block;
}

/* .cat2:hover .div{
    display: block !important;
}  */

.CategoryName {
    font-size: 54px;
    line-height: 1.10;
    font-weight: 800;
    color: white;
    padding-bottom: 5px;
    position: absolute;
    left: 40px;
    padding-top: 20px;

}

.bluetext {
    padding: 15px;
    /* max-width: 320px; */
    color: #fff;
    background: rgba(5, 159, 226, .8);
    margin-bottom: 15px;
    position: absolute;
    left: 40px;
    height: auto;
    margin-top: 90px;
}

.bluetext .shopbutton {
    font-size: 20px;
    font-weight: 600;
    position: absolute;
    left: 20px;
    color: #fff;
    text-decoration: none;
    /* padding: 13px 22px; */
    display: inline-block;
    margin-top: 20px;
}

/* #root > div > div > section:nth-child(4) > div > div > div > div:nth-child(2) > div.bluetext > div > button:hover{
    color: #059fe2;
} */
#root>div>div>section:nth-child(4)>div>div>div>div:nth-child(2)>div.bluetext>div>button:hover {
    color: #059fe2;
}

.sub-title {
    font-size: 30px;
    line-height: 1.2;
    font-weight: 600;

}

.title {
    font-size: 48px;
    font-weight: 600;
    line-height: 1;
}

.respairator {
    font-size: 54px;
    line-height: 1.10;
    font-weight: 800;
    color: white;
    padding-bottom: 5px;
    position: absolute;
    text-align: right;
    padding-left: 30px;
    padding-top: 20px;
}

.discount {
    font-size: 47px;
    line-height: 1.10;
    font-weight: 600;
    color: white;
    padding-bottom: 5px;
    position: absolute;
    padding-left: 163px;
    padding-top: 89px;
}

.upto {
    font-size: 47px;
    line-height: 1.10;
    font-weight: 700;
    color: white;
    padding-bottom: 5px;
    position: absolute;
    padding-top: 89px;
    padding-left: 30px;
}

.dicountbutton {
    font-size: 20px;
    font-weight: 600;
    position: absolute;
    /* right: 445px; */
    color: #fff;
    background: #059fe2;
    text-decoration: none;
    display: inline-block;
    margin-top: 160px;
    margin-left: 36px;
}

.dicountbutton .btn .btn-primary:hover {
    background: black;
}

.cat3 {
    display: none;
    position: absolute;
    right: -800px;
    top: 0px;
    background: white;
    width: 100%;
    height: 360px;
    overflow-y: scroll;

}

.cat3 ul li a:hover {
    color: #059fe2;
}

.cat3 ul li p {
    font-size: 14px;
    color: #545454;
    font-weight: 400;
    padding-left: 20px;
    padding-top: 7px;
    line-height: 12px;
    box-sizing: border-box;
    border: none;
}

.cat2:hover~div {
    display: block;
}

.cat3:hover {
    display: block;
}


@media screen and (max-width: 768px) {


    .Topbackground {
        height: 350px;
    }

    .CategoryName {
        font-size: 40px;
    }

    .sub-title {
        font-size: 23px;

    }

    .title {
        font-size: 27px;

    }

    .bluetext {
        padding: 10px;
        margin-top: 70px;
    }



    .respairator {
        font-size: 40px;

    }

    .discount {
        font-size: 37px;
        padding-left: 139px;
        padding-top: 73px;
    }

    .upto {
        font-size: 37px;
        padding-top: 73px;

    }

    .dicountbutton {
        margin-top: 124px;
        margin-left: 32px;
    }

}





@media screen and (max-width: 766px) {



    #root>div>div>section:nth-child(3)>div>div>div.owl-nav>button.owl-prev span {
        width: 5%;
    }

    #root>div>div>section:nth-child(3)>div>div>div.owl-nav>button.owl-next span {
        width: 5%;
    }


    #root>div>div>section:nth-child(5)>div>div>div.owl-nav>button.owl-prev span {

        width: 5%;

    }

    #root>div>div>section:nth-child(5)>div>div>div.owl-nav>button.owl-next span {

        width: 5%;

    }



    /* .........Eye Protection......... */

    #root>div>div>section:nth-child(6)>div>div>div.owl-nav>button.owl-prev span {
        width: 5%;

    }

    #root>div>div>section:nth-child(6)>div>div>div.owl-nav>button.owl-next span {

        width: 5%;

    }



    /* ..........Hand Protection.......... */

    #root>div>div>section:nth-child(7)>div>div>div.owl-nav>button.owl-prev span {

        width: 5%;

    }

    #root>div>div>section:nth-child(7)>div>div>div.owl-nav>button.owl-next span {

        width: 5%;

    }





    /* ........Head Protection......... */


    #root>div>div>section:nth-child(8)>div>div>div.owl-nav>button.owl-prev span {

        width: 5%;

    }

    #root>div>div>section:nth-child(8)>div>div>div.owl-nav>button.owl-next span {

        width: 5%;

    }

    /* Our Top Brands */
    #root>div>div>section:nth-child(9)>div>div>div.owl-nav>button.owl-prev span {

        width: 5%;

    }

    #root>div>div>section:nth-child(9)>div>div>div.owl-nav>button.owl-next span {
        width: 5%;

    }


    /* Respiratory Protection */
    #root>div>div>section:nth-child(10)>div>div>div.owl-nav>button.owl-prev span {

        width: 5%;

    }


    #root>div>div>section:nth-child(10)>div>div>div.owl-nav>button.owl-next span {
        width: 5%;

    }

    /* Hearing Protection */

    #root>div>div>section:nth-child(11)>div>div>div.owl-nav>button.owl-prev span {
        width: 5%;
    }


    #root>div>div>section:nth-child(11)>div>div>div.owl-nav>button.owl-next span {
        width: 5%;
    }

    /* ......Safety Clothing....... */
    #root>div>div>section:nth-child(12)>div>div>div.owl-nav>button.owl-prev span {
        width: 5%;
    }

    #root>div>div>section:nth-child(12)>div>div>div.owl-nav>button.owl-next span {
        width: 5%;
    }

    .CategoryName {
        font-size: 50px;
    }

    .sub-title {
        font-size: 30px;

    }

    .title {
        font-size: 35px;
        padding-bottom: 15px;

    }

    .bluetext {
        padding: 30px;
        margin-top: 100px;
    }



    .respairator {
        font-size: 50px;

    }

    .discount {
        font-size: 35px;
        line-height: 1.10;
        font-weight: 600;
        color: white;
        padding-bottom: 5px;
        position: absolute;
        padding-left: 139px;
        padding-top: 84px;
    }

    .upto {
        font-size: 30px;
        padding-top: 84px;

    }

    .dicountbutton {
        margin-top: 130px;
        margin-left: 32px;
    }

}

@media screen and (max-width: 600px) {
    .Topbackground {
        height: 300px;
    }

    #root>div>div>section:nth-child(3)>div>div>div.owl-nav>button.owl-prev span {
        width: 5%;
    }

    #root>div>div>section:nth-child(3)>div>div>div.owl-nav>button.owl-next span {
        width: 5%;
    }


    #root>div>div>section:nth-child(5)>div>div>div.owl-nav>button.owl-prev span {

        width: 5%;

    }

    #root>div>div>section:nth-child(5)>div>div>div.owl-nav>button.owl-next span {

        width: 5%;

    }



    /* .........Eye Protection......... */

    #root>div>div>section:nth-child(6)>div>div>div.owl-nav>button.owl-prev span {
        width: 5%;

    }

    #root>div>div>section:nth-child(6)>div>div>div.owl-nav>button.owl-next span {

        width: 5%;

    }



    /* ..........Hand Protection.......... */

    #root>div>div>section:nth-child(7)>div>div>div.owl-nav>button.owl-prev span {

        width: 5%;

    }

    #root>div>div>section:nth-child(7)>div>div>div.owl-nav>button.owl-next span {

        width: 5%;

    }





    /* ........Head Protection......... */


    #root>div>div>section:nth-child(8)>div>div>div.owl-nav>button.owl-prev span {

        width: 5%;

    }

    #root>div>div>section:nth-child(8)>div>div>div.owl-nav>button.owl-next span {

        width: 5%;

    }

    /* Our Top Brands */
    #root>div>div>section:nth-child(9)>div>div>div.owl-nav>button.owl-prev span {

        width: 5%;

    }

    #root>div>div>section:nth-child(9)>div>div>div.owl-nav>button.owl-next span {
        width: 5%;

    }


    /* Respiratory Protection */
    #root>div>div>section:nth-child(10)>div>div>div.owl-nav>button.owl-prev span {

        width: 5%;

    }


    #root>div>div>section:nth-child(10)>div>div>div.owl-nav>button.owl-next span {
        width: 5%;

    }

    /* Hearing Protection */

    #root>div>div>section:nth-child(11)>div>div>div.owl-nav>button.owl-prev span {
        width: 5%;
    }


    #root>div>div>section:nth-child(11)>div>div>div.owl-nav>button.owl-next span {
        width: 5%;
    }

    /* ......Safety Clothing....... */
    #root>div>div>section:nth-child(12)>div>div>div.owl-nav>button.owl-prev span {
        width: 5%;
    }

    #root>div>div>section:nth-child(12)>div>div>div.owl-nav>button.owl-next span {
        width: 5%;
    }


    .sub-title {
        font-size: 38px;

    }

    .title {
        font-size: 32px;


    }

    .bluetext {
        padding: 20px;
        margin-top: 80px;
    }

}

@media screen and (max-width: 500px) {

    .Topbackground {
        height: 280px;
    }


    #root>div>div>section:nth-child(1)>div>div>div>div>div.owl-nav>button.owl-prev span {

        width: 7%;

    }

    #root>div>div>section:nth-child(1)>div>div>div>div>div.owl-nav>button.owl-next span {

        width: 7%;

    }

    #root>div>div>section:nth-child(3)>div>div>div.owl-nav>button.owl-prev span {
        width: 8%;
    }

    #root>div>div>section:nth-child(3)>div>div>div.owl-nav>button.owl-next span {
        width: 8%;
    }


    #root>div>div>section:nth-child(5)>div>div>div.owl-nav>button.owl-prev span {

        width: 8%;

    }

    #root>div>div>section:nth-child(5)>div>div>div.owl-nav>button.owl-next span {

        width: 8%;

    }



    /* .........Eye Protection......... */

    #root>div>div>section:nth-child(6)>div>div>div.owl-nav>button.owl-prev span {
        width: 8%;

    }

    #root>div>div>section:nth-child(6)>div>div>div.owl-nav>button.owl-next span {

        width: 8%;

    }



    /* ..........Hand Protection.......... */

    #root>div>div>section:nth-child(7)>div>div>div.owl-nav>button.owl-prev span {

        width: 8%;

    }

    #root>div>div>section:nth-child(7)>div>div>div.owl-nav>button.owl-next span {

        width: 8%;

    }





    /* ........Head Protection......... */


    #root>div>div>section:nth-child(8)>div>div>div.owl-nav>button.owl-prev span {

        width: 8%;

    }

    #root>div>div>section:nth-child(8)>div>div>div.owl-nav>button.owl-next span {

        width: 8%;

    }

    /* Our Top Brands */
    #root>div>div>section:nth-child(9)>div>div>div.owl-nav>button.owl-prev span {

        width: 8%;

    }

    #root>div>div>section:nth-child(9)>div>div>div.owl-nav>button.owl-next span {
        width: 8%;

    }


    /* Respiratory Protection */
    #root>div>div>section:nth-child(10)>div>div>div.owl-nav>button.owl-prev span {

        width: 8%;

    }


    #root>div>div>section:nth-child(10)>div>div>div.owl-nav>button.owl-next span {
        width: 8%;

    }

    /* Hearing Protection */

    #root>div>div>section:nth-child(11)>div>div>div.owl-nav>button.owl-prev span {
        width: 8%;
    }


    #root>div>div>section:nth-child(11)>div>div>div.owl-nav>button.owl-next span {
        width: 8%;
    }

    /* ......Safety Clothing....... */
    #root>div>div>section:nth-child(12)>div>div>div.owl-nav>button.owl-prev span {
        width: 8%;
    }

    #root>div>div>section:nth-child(12)>div>div>div.owl-nav>button.owl-next span {
        width: 8%;
    }




    .sub-title {
        font-size: 23px;

    }

    .CategoryName {
        font-size: 40px;
    }

    .title {
        font-size: 22px;


    }

    .bluetext {
        padding: 15px;
        margin-top: 68px;
    }

    .respairator {
        font-size: 50px;

    }

    .discount {
        font-size: 35px;
        line-height: 1.10;
        font-weight: 600;
        color: white;
        padding-bottom: 5px;
        position: absolute;
        padding-left: 139px;
        padding-top: 84px;
    }

    .upto {
        font-size: 30px;
        padding-top: 84px;

    }

    .dicountbutton {
        margin-top: 130px;
        margin-left: 32px;
    }

}

@media screen and (max-width: 425px) {


    .card-body {
        flex: 1 1 auto;
        padding: 0px !important;
        color: var(--bs-card-color);
    }

    #root>div>div>section:nth-child(1)>div>div>div>div>div.owl-nav>button.owl-prev span {

        width: 7%;

    }

    #root>div>div>section:nth-child(1)>div>div>div>div>div.owl-nav>button.owl-next span {

        width: 7%;

    }

    .Topbackground {
        height: 250px;
    }

    .BestSelling {
        font-size: 21px;
    }

    .sub-title {
        font-size: 20px;

    }

    .CategoryName {
        font-size: 30px;
    }

    .title {
        font-size: 20px;


    }

    .bluetext {
        padding: 10px;
        margin-top: 57px;
    }

    .respairator {
        font-size: 30px;

    }

    .discount {
        font-size: 25px;
        padding-top: 59px;
        padding-left: 92px;

    }

    .upto {
        font-size: 20px;
        padding-top: 64px;

    }

    .dicountbutton {
        margin-top: 101px;
        margin-left: 32px;
    }



}

@media screen and (max-width: 375px) {

    .Topbackground {
        height: 230px;
    }

    .smpadding{
        padding: 0px 30px !important;
    }


    .CategoryName {
        font-size: 25px;
    }

    .sub-title {
        font-size: 16px;

    }

    #root>div>div>section:nth-child(3)>div>div>div.owl-nav>button.owl-prev span {
        width: 10%;
    }

    #root>div>div>section:nth-child(3)>div>div>div.owl-nav>button.owl-next span {
        width: 10%;
    }


    #root>div>div>section:nth-child(5)>div>div>div.owl-nav>button.owl-prev span {

        width: 10%;

    }

    #root>div>div>section:nth-child(5)>div>div>div.owl-nav>button.owl-next span {

        width: 10%;

    }



    /* .........Eye Protection......... */

    #root>div>div>section:nth-child(6)>div>div>div.owl-nav>button.owl-prev span {
        width: 10%;

    }

    #root>div>div>section:nth-child(6)>div>div>div.owl-nav>button.owl-next span {

        width: 10%;

    }



    /* ..........Hand Protection.......... */

    #root>div>div>section:nth-child(7)>div>div>div.owl-nav>button.owl-prev span {

        width: 10%;

    }

    #root>div>div>section:nth-child(7)>div>div>div.owl-nav>button.owl-next span {

        width: 10%;

    }





    /* ........Head Protection......... */


    #root>div>div>section:nth-child(8)>div>div>div.owl-nav>button.owl-prev span {

        width: 10%;

    }

    #root>div>div>section:nth-child(8)>div>div>div.owl-nav>button.owl-next span {

        width: 10%;

    }

    /* Our Top Brands */
    #root>div>div>section:nth-child(9)>div>div>div.owl-nav>button.owl-prev span {

        width: 10%;

    }

    #root>div>div>section:nth-child(9)>div>div>div.owl-nav>button.owl-next span {
        width: 10%;

    }


    /* Respiratory Protection */
    #root>div>div>section:nth-child(10)>div>div>div.owl-nav>button.owl-prev span {

        width: 10%;

    }


    #root>div>div>section:nth-child(10)>div>div>div.owl-nav>button.owl-next span {
        width: 10%;

    }

    /* Hearing Protection */

    #root>div>div>section:nth-child(11)>div>div>div.owl-nav>button.owl-prev span {
        width: 10%;
    }


    #root>div>div>section:nth-child(11)>div>div>div.owl-nav>button.owl-next span {
        width: 10%;
    }

    /* ......Safety Clothing....... */
    #root>div>div>section:nth-child(12)>div>div>div.owl-nav>button.owl-prev span {
        width: 10%;
    }

    #root>div>div>section:nth-child(12)>div>div>div.owl-nav>button.owl-next span {
        width: 10%;
    }

    .item {
        height: 420px;
    }

    .title {
        font-size: 18px;


    }

    .bluetext {
        padding: 6px;
        margin-top: 48px;
    }

    .BestSelling {
        font-size: 16px;
    }
}

/* Privacy Policy Page Css */

.ct_privacy_para {
    font-family: Barlow, sans-serif;
    line-height: 28px;
    font-size: 16px;
    margin-bottom: 15px;

}

.bold {
    font-weight: 600;
    padding-top: 20px;
}

.privacysmalltext {
    padding-top: 20px;

}

.info {
    font-weight: 600;
    padding-top: 20px;
}

.infotext {
    padding-top: 20px;
    padding-bottom: 15px;
}

.info2 {
    padding-top: 20px;
    font-weight: 600;
}

.main-header .header-cart a img {
    padding-right: 121px;
}



.header-cart span.cart-heading {
    padding-right: 120px;
}

@media screen and (max-width: 575px) {
    .search-with-cat.search-width form .inner-form .input-field.first-wrap {
        min-width: 90% !important;
    }
}



@media screen and (max-width: 768px) {

    .search-with-cat.search-width form .inner-form .input-field.first-wrap {
        min-width: 80% !important;
    }
}




.search-with-cat form .inner-form .input-field input {
    height: 100%;
    background: #fbfbfb;
    display: block;
    width: 101%;
    padding: 10px 10px;
    font-size: 16px;
    color: #8f8f8f;
    border: 1px solid #e5e5e5;
}


.search-with-cat form .inner-form .input-field .btn-search {
    height: 100%;
    width: 101%;
    margin-right: 20px;
    white-space: nowrap;
    font-size: 20px;
    color: #fff;
    border: 0;
    cursor: pointer;
    position: relative;
    z-index: 0;
    background: #060407;
    transition: all .2s ease-out, color .2s ease-out;
    font-weight: 300;
}



@media only screen and (max-width: 767px) {
    .main-header .search-with-cat {
        bottom: -58px;
    }
}

@media only screen and (max-width: 992px) {
    .box1 {
        padding: 20px;
        background: #fff;
        border: 1px solid #ececec;
        margin-bottom: 5px;
        /* width: 100px; */
    }

    .finalwidth{
        width: 100%;
       }
}



@media only screen and (max-width: 768px) {
    header .main-header .logo img {
        max-width: 106px;
    }


    .col-md-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;

    }

}



.post-block1 {
    display: flex;
    border: 1px solid #d8d8d8;
    padding: 5px;
    margin-bottom: 15px;
}


.post-block1 img {
    width: 80%;
    min-width: 235px;
    max-width: 235px;
}


.comment-block1 h2 {
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    color: #333333;
    padding-left: 20px;
    padding-bottom: 15px;
    padding-top: 25px;
}

.entry-content1 {
    padding-left: 20px;
    font-size: 18px;
    line-height: 24px;
    color: #565656;
    padding-bottom: 20px;
}

.post-footer1 {
    padding-top: 40px;
}

.post-date1 {
    color: #999;
    font-size: 13px;
    line-height: 24px;
    padding-left: 20px;
}

.post-date1,
.post-footer1 .social-share {
    padding-bottom: 10px;
    color: #999;
    font-size: 13px;
    line-height: 24px;
}


.post-footer1 .post-meta .post-date1,
.post-footer1 .social-share {
    color: #999;
    font-size: 13px;
    line-height: 24px;
}

.social-share {
    display: flex;
}

.post-meta {
    display: flex;
    justify-content: space-between !important;
}

.popular-post-block .block-title {
    padding-bottom: 40px;
    font-size: 20px;
    font-weight: 600;
    color: #2f2f2f;
    border-bottom: 2px solid #e1e1e1;
    padding-bottom: 18px;


}


.popular-post1-block .block-title {
    padding-bottom: 40px;
    font-size: 20px;
    font-weight: 600;
    color: #2f2f2f;
    border-bottom: 2px solid #e1e1e1;
    padding-bottom: 18px;


}













.maindiv {
    display: flex;
}


.popular-tags {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    background: rgba(5, 159, 226, .1);
    padding: 13px 17px;
    color: #059fe2;
    display: inline-block;
    margin: 5px 5px 5px 0;
    float: left;

}

.popular-tag1 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    background: rgba(5, 159, 226, .1);
    padding: 13px 17px;
    color: #059fe2;
    display: inline-block;
    margin: 5px 5px 5px 0;


}

@media only screen and (max-width: 1408px) {
    .menuicon {
        display: none !important;
    }
}






/* ........ ADDRESS PAGE CSS........ */

.hand1 {
    display: inline-flex;
    width: 100%;
    border: 1px solid #ececec;
    background-color: #059fe2;
    padding: 20px;
}

.cattext1 a {
    color: white;
    font-size: 20px;
}

.userIcon {
    color: black;
}

/* .invoimg{
    background:#059fe2 ;
} */

.hand>.cattext a:hover {
    color: white;
}

.addressbox {
    border: 1px solid #e6e6e6;
    background: #fff;
    padding: 22px;
    align-self: flex-start;
    margin-top: 49px;
}

.add {
    font-size: 21px;
    font-weight: 600;
    color: #262626;
    border-bottom: 1px solid #ededed;
    padding-bottom: 20px;
    margin-bottom: 20px;

}

.add2 {
    display: inline-block;
    font-size: 16px;
    color: #000;
    font-weight: 600;
    text-transform: uppercase;
    border: 2px solid #059fe2;
    padding: 12px 18px 12px 18px;
    position: relative;
}

.add2:hover {
    background: #059fe2;
    color: white;
}

.add2 .icplus {
    color: #059fe2;
}


.add2:hover>.icplus {
    color: white;
}


.addcard {
    background: #f9f9f9;
    border: 1px solid #e2e2e2;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 30px;
    padding-right: 50px;
    position: relative;
    margin-bottom: 20px;
    margin-top: 20px;
}

.addHeading {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}

.address-content {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #4c4c4c;
}

.threedot {
    position: absolute;
    right: 15px;
}



.hand {
    display: inline-flex;
    width: 100%;
    border: 1px solid #ececec;
    background-color: white;
    padding: 20px;

}


.hand:hover {
    background: #059fe2;
    /* color: white; */
}

.hand:hover>.cattext a {
    color: white;

}

.hand:hover>.cattext .invoimg {
    background: white;

}


.hand .cattext a {
    color: black;
    font-size: 20px;
}

#primary .tool-bar select {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    padding: 12px 18px 12px 40px;
}


#primary .tool-bar .dropdown-box:after {
    content: "\f078";
    font-family: "FontAwesome";
    font-weight: 600;
    color: #6d6d6d;
    position: absolute;
    right: 10px;
    line-height: 12px;
    top: 17px;
    font-size: 17px;

}

#primary .tool-bar label {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    padding-bottom: 15px;

}



/* .........BULK-ORDER PAGE MEDIA....... */

.firstinput {
    width: 30%;
}

.longinput {
    width: 60%;
}

.bulkorder-heading h3 {
    font-weight: 600;
    color: #262626;
    font-size: 26px;
}

.bulkorder-heading p {
    font-weight: 400;
    color: #565656;
    font-size: 18px;
}




@media only screen and (max-width: 500px) {
    .firstinput {
        width: 42%;
    }

    .longinput {
        width: 85%;
    }

    .bulkorder-heading h3 {
        font-size: 23px;
    }

    .bulkorder-heading p {

        font-size: 16px;
    }
}

/* ............MY ITEMS PAGE CSS............. */


.myItems {
    font-size: 21px;
    font-weight: 600;
    color: #262626;
    border-bottom: 1px solid #ededed;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.Myitems-box {
    border: 1px solid #e6e6e6;
    background: #fff;
    padding: 22px;

}

/* ......WHISHLIST PAGE CSS....... */

.imageheart1 {
    font-size: 30px;
    padding-right: 20px;
    color: rgba(128, 128, 128, 0.548);
    position: absolute;
    top: 26px;
    right: 0px;
    display: flex;
    justify-content: right;
    flex-direction: column;
    opacity: 0;
    transition: 0.6s;

}

.rightone:hover>.imageheart1 {
    color: #059fe2;
    opacity: 1;
}




/* ...........PASSWORD PAGE CSS............ */

.curpassword {
    font-size: 16px;
    line-height: 32px;

}

#product-listing-sec>div>div>div.col-lg-9.col-sm-12.Myitems-box>div>div>input {
    border: 1px solid #dadada;
    border-radius: 0;
    padding: 0 15px;
    color: #000;
    margin-bottom: 10px;
    height: 45px;
    width: 40%;
}

.action-btn {
    width: 40%;
    text-align: right;

}

#send_otp {
    border: 2px solid #059fe2;
    border-radius: 0;
    background: white;
    color: #059fe2;
    margin-bottom: 10px;
    height: 45px;
    width: 40%;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 12px 18px;
    transition: all .2s ease-in-out;
}

#send_otp:hover {
    background: #059fe2;
    color: white;
}

@media only screen and (max-width: 768px) {

    #product-listing-sec>div>div>div.col-lg-9.col-sm-12.Myitems-box>div>div>input {
        width: 50%;
    }

    .action-btn {
        width: 50%;
    }

    #send_otp {
        width: 47%;
    }

}


@media only screen and (max-width: 600px) {

    #product-listing-sec>div>div>div.col-lg-9.col-sm-12.Myitems-box>div>div>input {
        width: 47%;
    }

    .action-btn {
        width: 47%;
    }

    /* #send_otp{
            width: 48%; 
        } */

}

@media only screen and (max-width: 500px) {

    #product-listing-sec>div>div>div.col-lg-9.col-sm-12.Myitems-box>div>div>input {
        width: 70%;
    }

    .action-btn {
        width: 70%;
    }

    #send_otp {
        width: 47%;
    }

}


@media only screen and (max-width: 425px) {

    #product-listing-sec>div>div>div.col-lg-9.col-sm-12.Myitems-box>div>div>input {
        width: 80%;
    }

    .action-btn {
        width: 80%;
    }

    #send_otp {
        width: 47%;
    }

}




   /* ...........MY-RFQ PAGE CSS............. */
.order-details{
 border-bottom: 1px solid #dee2e6!important;
}
.order-details-heading{
    font-weight: 600;
    font-size: 15px;

}
.created{
    color: #87ceeb;
    font-weight: 600;
    font-size: 15px;

}


/* ...........Profile page css............. */
.order-details-heading1{
    font-size: 14px!important;
    color: #666;
}
.imgView{
    height: 150px;
    width: 270px;
    padding-bottom: 15px;
}

.Vendercode{
    padding-top: 10px;
    font: inherit;
    vertical-align: baseline;
    padding-bottom: 20px;
}

.profile-infobox{
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 12px 0;
   
}


.btn-btn-save{
    background-color: #059fe2;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    transition: all .4s ease-in-out;
    padding-left: 15px;
    padding-right: 15px;

}

.btn-btn-save:hover{
background: black;
}


@media only screen and (max-width: 375px) {
    .Vendercode{
        font-size: 14px;
    }
}



/* ...........Cart page css......... */
.product-block{
    background: #fff;
    border: 1px solid #e6e6e6;
    margin-bottom: 10px;
    display: flex;

}
.left-part{
    /* padding: 12px 15px; */
    width: 75%;
    align-items: center;
    display: flex;
}
.pro-name{
    font-size: 14px;
    font-weight: 700;
    color: #333;
    line-height: 26px;
}


 .qty {
    padding-right: 40px;
}
.total-price{
    background-color: #f6f6f6;
    padding: 10px 25px;
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin: 2px;
}
/* .deleteimg{
    background: #fff;
    font-size: 14px;
    border: 2px solid #f1f1f1;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #059fe2;
    position: absolute;
    top: 19px;
    right: 12px;
} */

.lebal{
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 32px;

}
.value{
    font-size: 19px;
    color: #059fe2;
}
.payment-summary{
    background-color: #fff;
    border: 1px solid #e6e6e6;
    margin-bottom: 20px;
}

.total-amount{
    background: #f6f6f6;
    padding-top: 10px;
    padding-bottom: 10px;
}
.total-amount p{
    font-size: 16px;
    line-height: 26px;
    display: flex;
    justify-content: space-between;
}
.total-amount p strong{
    font-weight: 600;
}
.t-strong{
    text-transform: capitalize!important;
    font-size: 15px;
}
.payment-summary .plr-20{
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.header-part h2{
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;

}

.total-content p{
    font-size: 16px;
    line-height: 26px;
    display: flex;
    justify-content: space-between;
}
.total-content p strong{
    font-weight: 600;
}

.amount-payable p{
    line-height: 26px;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.amount-payable p strong{
    color: #059fe2;
    font-weight: 700;
    text-transform: uppercase;
}
.proceed-to-check .check-button{
    font-size: 20px;
    font-weight: 600;
    background: #059fe2;
    color: #fff;
    padding: 18px 30px;
    display: block;
    text-align: center;
    margin-left: auto;
}
.proceed-to-check {
    text-align: center;
    margin: 20px 0;
}
.qty-button{
    width: 54px;
    height: 22px;
    text-align: center;
    font-weight: 500;
    border: 1px solid #e7e7e7;
}

.product-img{
    vertical-align: middle;
    box-sizing: border-box;
    max-width: 200px;
    width: 100%;

}

/* profile update input form */

.profile-update-form form .field label {
    font-size: 16px;
    line-height: 32px;
}

/* end profile update input form */










