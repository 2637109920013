@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&family=Roboto+Condensed:wght@300;400;500&display=swap');


*{
    font-family: 'Inter', sans-serif;
font-family: 'Roboto Condensed', sans-serif;
}
.header-cart span.cart-heading {
   color: #000;
   font-size: 20px;
   font-weight: 500;
}
#header a.router-link-exact-active{
 color:#fff;
}

.newwidth{
    --bs-modal-width: 438px !important;
}
/* .main-header .search-with-cat {
   padding-right: 23px;
} */
.search-with-cat form .inner-form .input-field.first-wrap {
 min-width: 631px;
}
.search-with-cat.search-width form .inner-form .input-field.first-wrap {
 min-width: 679px;
}
.main-header .header-cart{
margin-right: 15px;
}

.hidemobileuser{
display: block;
}

.showmobileuser{
display: none;
}

.forgot {
   color: #007bff;
}
.user-setiing {
   position: absolute;
   right: 0;
   top: calc(100%);
   padding-top: 10px;
   width: 200px;
   z-index: 3;
   display: none;
}
.main-header .header-cart a img {
   padding-right: 12px !important;
}

.header-cart span.cart-heading {
   padding-right: 0px !important;
}
.user-setiing ul {
   border: 1px solid #ececec;
   border-top: none;
   background-color: #fff;
}
.header-right .user-login:hover .user-setiing {
   display: block;
}
.header-right .user-setiing li a {
   color: #000;
   font-size: 17px;
   padding: 17px 20px;
   letter-spacing: 0.2px;
   font-weight: 400;
   display: block;
   border-top: 1px solid #ececec;
   text-align: center;
}
.signin-modal .modal-body {
   padding: 0 !important;
}

.header-right {
   background: white;
   font-size: 23px;
   font-weight: 500;
   width: 177px;
   outline: none;
}
.header-right .user-login {
   position: relative;
   padding: 10px 0;
}
.header-right .user-login i {
   padding-right: 5px;
   display: none;
}
.header-right .user-login > a {
   padding: 12px 0 15px;
   display: block;
}
.header-right .user-login a {
   color: #000;
   font-size: 20px;
   font-weight: 500;
   text-align: start;
}
.header-right .user-setiing li a {
   color: #000;
   font-size: 17px;
   padding: 17px 20px;
   letter-spacing: 0.2px;
   font-weight: 400;
   display: block;
   border-top: 1px solid #ececec;
   text-align: center;
}
.header-right select.lang-picker.ui.dropdown option {
   font-size: 10px;
   color: #fff;
   background: #000;
   padding: 5px;
   border: 0;
}




.custozsearch{
   width:50%;
}
.custozsearch2{
   width:100%;
   display: flex;
   justify-content: center;
}
.custozsearch2 form{
   width: 96%;
}


.navbar {
   /* --bs-navbar-toggler-focus-width: 0rem !important;  */
   position: relative;
}

a{
    text-decoration: none;
}

.hideinmobile{
    display: block !important;
    z-index: 2;
}
.showinmobile{
    display: none !important;
}

.searchwidth{
width: 95%;
}

.searchbtnwidth{
    width: 5%;
}


@media only screen and (max-width:1900px) {
    .hideinmobile{
        display: block !important;
    z-index: 2;
    }

    .showinmobile{
        display: none !important;
    }
}
@media only screen and (max-width:1400px) {
    .hideinmobile{
        display: block !important;
        z-index: 2;
    }

    .showinmobile{
        display: none !important;
    }

    /* .main-header .header-cart a {
        margin-left: 92px;
     } */
}
@media only screen and (max-width:990px) {
    .hideinmobile{
        display: none !important;
    z-index: -1;

    }

    .showinmobile{
        display: flex !important;
    }
    .searchwidth{
        width: 95%;
        }
        
        .searchbtnwidth{
            width: 5%;
        }
}

@media only screen and (max-width:992px) {
    .main-header .header-cart a {
       margin-left: 92px;
    }
}

@media only screen and (max-width:700px) {
    .hideinmobile{
        display: none !important;
    z-index: 2;

    }
    .searchwidth{
        width: 92%;
        }
        
        .searchbtnwidth{
            width: 8%;
        }

    .showinmobile{
        display: flex !important;

    }
}
@media only screen and (max-width:675px) {
    .hideinmobile{
        display: none !important;
    }
    .showinmobile{
        display: flex !important;
    }

    .main-header .header-cart a {
        margin-left: 31px;
    }
    .header-right {
        background: white;
        font-size: 23px;
        font-weight: 500;
        width: 115px;
        outline: none;
    }
}

@media only screen and (max-width:500px) {
    .main-header .header-cart a {
        margin-left: 42px;
     }

     .hidemobileuser{
        display: none;
        }
        
        .showmobileuser{
        display: block;
        font-size: 26px;
        }
        .main-header .header-cart a {
            margin-left: 31px;
        }
        .header-right .user-login a {
            color: black;
            font-size: 20px;
            font-weight: 500;
            text-align: start;
            padding: 0px;
        }
        .header-right {
            background: white;
            font-size: 23px;
            font-weight: 500;
            width: 115px;
            .header-right .user-login a {
                color: black;
                font-size: 20px;
                font-weight: 500;
                text-align: start;
                padding: 0px;
            }
            outline: none;
        }
}
@media only screen and (max-width:440px) {
    .main-header .header-cart a {
        margin-left: 42px;
     }

     .hidemobileuser{
        display: none;
        }
        
        .showmobileuser{
        display: block;
        font-size: 26px;
        }
        .main-header .header-cart a {
            margin-left: 31px;
        }
        .header-right {
            background: white;
            font-size: 23px;
            font-weight: 500;
            width: 80px;
            outline: none;
        }
}

@media only screen and (max-width:375px) {
    .main-header .header-cart a {
        margin-left: 22px;
     }
     .hidemobileuser{
        display: none;
        }
        
        .showmobileuser{
        display: block;
        font-size: 26px;
        }

        .main-header .header-cart a {
            margin-left: 31px;
        }
        .main-header .header-cart a {
            margin-left: 6px;
        }
        .header-right {
            background: white;
            font-size: 23px;
            font-weight: 500;
            width: 74px;
            outline: none;
        }
}